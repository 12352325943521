import React, { useEffect, useState } from 'react';
import {
    createStyles,
    makeStyles,
    Theme
} from '@material-ui/core/styles';
import {
    Button,
    Card,
    Container,
    Grid,
    Typography
} from '@material-ui/core';
import './profilePage.css';
import LayoutSidebar from '../../sidebar/LayoutSidebar';
import { red } from '@material-ui/core/colors';
import { useSelector } from 'react-redux';
import {
    checkOldPassword,
    resetChangePassword,
    resetNewAccount,
    resetPassword,
    selectAccount,
    selectChangedPassword,
    selectCheckPassword,
    selectCurrentAccountByEmail
} from '../../../slices/accountSlice';
import CustomAccordion from '../../accordion/accordion';
import InformationCard from '../../cards/informationCard';
import CompanyInformationCard from '../../cards/companyInformationCard';
import ResetPassword from '../../../models/login/resetPassword';
import { useAppDispatch } from '../../../app/hooks';
import CheckPasswordRequest from '../../../models/login/checkPasswordRequest';
import { setSnackbar } from '../../../slices/snackbarSlice';
import SnackbarModel from '../../../models/snackbarModel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.up('md')]: {
                marginTop: "100px",
            },
            [theme.breakpoints.down('md')]: {
                marginTop: "50px",
            },
        },
        avatar: {
            backgroundColor: red[500],
            width: "200px",
            height: "200px"
        },
        button: {
            background: "#5D9427",
            color: "white",
            marginTop: "5px",
            fontWeight: 700,
            fontSize: "15px",
            float: "right",
            width: "90px"
        },
        title: {
            color: "#696969"
        },
        titleWrapper: {
            marginTop: "10px",
            bottom: 0
        },
        companyCardWrapper: {
            marginTop: "5px",
            padding: "10px"
        },
        accordionWrapper: {
            marginTop: "20px"
        },
        informationCardWrapper: {
            padding: "10px"
        }
    }),
);

export default function ProfilePage() {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const account = useSelector(selectAccount);
    const isChecked = useSelector(selectCheckPassword);
    const isPassChanged = useSelector(selectChangedPassword);
    const accByEmail = useSelector(selectCurrentAccountByEmail);

    const INIT_CHANGE_PASSWORD_REQUEST: ResetPassword = {
        code: "",
        email: account?.email as string,
        oldPassword: "",
        password: ""
    }

    const INIT_CHECK_PASSWORD: CheckPasswordRequest = {
        email: account?.email as string,
        password: "",
    }

    const [password, setPassword] = useState({
        passwordNew: "",
    });

    const [changePasswordRequest, setChangePasswordRequest] = useState<ResetPassword>(INIT_CHANGE_PASSWORD_REQUEST);
    const [checkOldPass, setCheckOldPass] = useState<CheckPasswordRequest>(INIT_CHECK_PASSWORD);

    const handleSubmit = (event: any) => {
        event.preventDefault();
    };


    const onBlurOldPassword = (e: any) => {
        const value = e.target.value;
        const name = e.target.name;
        setPassword({
            ...password,
            [name]: value
        })
        const updatePass = { ...changePasswordRequest }
        const updateCheckPassword = { ...checkOldPass }
        if (name === "passwordOld") {
            updatePass.oldPassword = value;
            updateCheckPassword.password = value;
        }
        if (name === "passwordConfirm") {
            updatePass.password = value;
        }
        setCheckOldPass(updateCheckPassword);
        dispatch(checkOldPassword(updateCheckPassword));
        setChangePasswordRequest(updatePass);
    };

    const handleSaveNewPassword = () => {
        dispatch(resetPassword(changePasswordRequest));
    };

    const clearChangePasswordFields = () => {
        dispatch(resetNewAccount());
        const updatePass: ResetPassword = {
            code: "",
            email: "",
            oldPassword: "",
            password: ""
        };
        const updateCheckPass: CheckPasswordRequest = {
            email: "",
            password: ""
        }
        setPassword({
            ...password,
            passwordNew: "",
        })
        dispatch(resetChangePassword());
        setChangePasswordRequest(updatePass);
        setCheckOldPass(updateCheckPass);
    };

    useEffect(() => {
        if (isPassChanged && isPassChanged !== null) {
            const snackbar: SnackbarModel = {
                message: "You have successfully changed a password",
                severity: "success",
                show: true,
            }
            dispatch(setSnackbar(snackbar));
            clearChangePasswordFields();
        } else if (isPassChanged === false && isPassChanged !== null) {
            const snackbar: SnackbarModel = {
                message: "Creating an account failed, please check the entered data and try again.",
                severity: "error",
                show: true,
            }
            dispatch(setSnackbar(snackbar));
        }
    }, [isPassChanged])

    return (
        <LayoutSidebar>
            <Container
                id="profile-page_container"
                maxWidth="lg"
                className={classes.root}
            >
                <form onSubmit={handleSubmit}>
                    <Grid
                        item
                        md={12}
                        className={classes.titleWrapper}
                    >
                        <Typography
                            id="profile-page_title-information"
                            variant="h5"
                            className={classes.title}
                        >
                            Information
                        </Typography>
                    </Grid>
                    <Card id="profile-page_card-information" className={classes.informationCardWrapper}>
                        <InformationCard accountByEmail={accByEmail} />
                    </Card>
                    <Grid
                        item
                        md={12}
                        className={classes.titleWrapper}
                    >
                        <Typography
                            id="profile-page_title-company"
                            variant="h5"
                            className={classes.title}
                        >
                            Company information
                        </Typography>
                    </Grid>
                    <Card id="profile-page_card-company" className={classes.companyCardWrapper}>
                        <CompanyInformationCard />
                    </Card>
                    <Grid
                        id="profile-page_wrapper_accordion"
                        container
                        className={classes.accordionWrapper}
                    >
                        <CustomAccordion
                            onBlurOldPassword={onBlurOldPassword}
                            isChecked={isChecked}
                            changePasswordRequest={changePasswordRequest}
                            password={password}
                            checkOldPass={checkOldPass}
                        />
                    </Grid>
                    <Grid
                        item
                        md={12}
                    >
                        <Button
                            onClick={handleSaveNewPassword}
                            id="profile-page_button_save"
                            type="submit"
                            size="small"
                            className={classes.button}
                            disabled={isChecked === false || isChecked === null}
                        >
                            Save
                        </Button>
                    </Grid>
                </form>
            </Container>
        </LayoutSidebar>
    );
};