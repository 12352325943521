import React from "react";
import {
  BrowserRouter,
  Route,
  Switch
} from "react-router-dom";
import AccountPage from "../features/pages/accountPage/accountPage";
import AddClientPage from "../features/pages/addClientPage/addClientPage";
import AddDocumentPage from "../features/pages/addDoccumentPage/addDocumentPage";
import DashboardPage from "../features/pages/dashboardPage/dashboardPage";
import LoginPage from "../features/pages/loginPage/loginPage";
import PasswordChangePage from "../features/pages/passwordChangePage/passwordChangePage";
import ProfilePage from "../features/pages/profilePage/profilePage";
import TemplatePage from "../features/pages/templatePage/templatePage";
import {
  loginPage as loginPageRoute,
  dashboardPage as dashboardPageRoute,
  addDocument as addDocumentRoute,
  addClient as addClientRoute,
  template as templateRoute,
  profilePage as profileRoute,
  passwordChange as passwordChangeRoute,
  accountPage as accountPageRoute
} from "./routes";

export default function Routing() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={loginPageRoute()} component={LoginPage}></Route>
        <Route exact path={dashboardPageRoute()} component={DashboardPage}></Route>
        <Route exact path={accountPageRoute()} component={AccountPage}></Route>
        <Route exact path={addDocumentRoute()} component={AddDocumentPage}></Route>
        <Route exact path={addClientRoute()} component={AddClientPage}></Route>
        <Route exact path={templateRoute()} component={TemplatePage}></Route>
        <Route exact path={profileRoute()} component={ProfilePage}></Route>
        <Route exact path={passwordChangeRoute()} component={PasswordChangePage}></Route>
      </Switch>
    </BrowserRouter>
  );
};