import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
    createStyles,
    makeStyles,
    Theme,
    alpha,
    useTheme
} from '@material-ui/core/styles';
import {
    Grid,
    Slide,
    useMediaQuery,
    Zoom
} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PictureInPictureAltOutlinedIcon from '@material-ui/icons/PictureInPictureAltOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import LogoFull from '../../images/easybusinessLogo.png';
import LogoSmall from '../../images/easybusinessLogoSmall.png';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {
    dashboardPage as dashboardRoute,
    accountPage as accountPageRoute,
    loginPage as loginPageRoute,
    profilePage as profilePageRoute
} from '../../app/routes';
import { useHistory } from 'react-router-dom';

import './sidebar.css'
import { useAppDispatch } from '../../app/hooks';
import {
    logout,
    selectAccount,
    selectCurrentAccountByEmail,
} from '../../slices/accountSlice';
import { useSelector } from 'react-redux';
import ProfileHeader from '../profileHeader/profileHeader';

const drawerWidth = 250;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            position: "relative"
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            boxShadow: "none",
            background: "transparent",
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            [theme.breakpoints.down("md")]: {
                display: "none"
            },
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 36
        },
        menuButtonMobile: {
            marginRight: 36,
            color: "#3C7F9C"
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            zIndex: 1,
            top: 0,
            left: 0,
            position: "relative",
            [theme.breakpoints.down("md")]: {
                display: "none"
            },
        },
        drawerOpen: {
            width: drawerWidth,
            background: "#F1F0F0",
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(6) + 1,
            background: "#F1F0F0",
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        search: {
            border: '1px solid gray',
            borderRadius: '40px',
            position: 'relative',
            backgroundColor: alpha(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: alpha(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        listItem: {
            color: "#3C7F9C",
            '&:hover': {
                background: "#DBE3EA",
                borderRadius: "19px 0px 0px 19px",
                lineHeight: "20px",
                color: "#3C7F9C",
                borderRight: "transparent",
            },
            '&:focus': {
                background: "#DBE3EA",
                color: "#3C7F9C",
                borderRadius: "19px 0px 0px 19px",
            },
        },
        listItem_mobile: {
            color: "#3C7F9C",
            width: "100%",
            justifyContent: "center",
            '&:hover': {
                background: "#DBE3EA",
                borderRadius: "19px 0px 0px 19px",
                lineHeight: "20px",
                color: "#3C7F9C",
            },
            '&:focus': {
                background: "#DBE3EA",
                color: "#3C7F9C",
                borderRadius: "19px 0px 0px 19px",
            },
        },
        listItem_text_mobile: {
            color: "#ffff",
            textAlign: "center",
            justifyContent: "center",
            '&:hover': {
                background: "#DBE3EA",
                borderRadius: "19px 0px 0px 19px",
                lineHeight: "20px",
                color: "#3C7F9C",
            },
            '&:focus': {
                background: "#DBE3EA",
                color: "#3C7F9C",
                borderRadius: "19px 0px 0px 19px",
            },
        },
        notificationIcon: {
            color: "#CA9100",
            marginRight: "10px"
        },
        listItem_text: {
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "20px",
            marginLeft: "5px"
        },
        toolbar_mobile: {
            [theme.breakpoints.down("xs")]: {
                display: "none"
            },
        },
        list_wrapper: {
            background: "#3C7F9C"
        },
        divider: {
            background: "#ffff"
        },
        header: {
            background: "#EFEFEF"
        },
    }),
);

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export default function SideBar() {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const [open, setOpen] = React.useState(false);
    const [auth, setAuth] = useState(true);
    const accByEmail = useSelector(selectCurrentAccountByEmail);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const theme = useTheme();

    const openProfile = Boolean(anchorEl);

    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseProfile = () => {
        setAnchorEl(null);
    };

    const account = useSelector(selectAccount);

    const handleLoginPage = () => {
        dispatch(logout());

    };

    const handleDashboard = () => {
        history.push(dashboardRoute());
    };

    const handleAccount = () => {
        history.push(accountPageRoute());
    };

    const handleProfilePage = () => {
        history.push(profilePageRoute());
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (account?.email === "") {
            history.push(loginPageRoute());
        }
    }, [account]);

    return (
        <div style={{ position: "relative" }}>
            <CssBaseline />
            <AppBar
                id="sidebar_appBar"
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar id="sidebar_toolbar" className={classes.toolbar_mobile}>
                    <IconButton
                        id="sidebar_iconButton_menuIcon"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >
                        <MenuIcon id="sidebar_icon_menuIcon" style={{ color: "#3C7F9C" }} />
                    </IconButton>
                    <Grid id="sidebar_header" container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <div className="form-group mt-3">
                                <div className="header_form-input">
                                    <span id="sidebar_iconButton_searchIcon" className="header_icon_input">
                                        <SearchIcon id="sidebar_icon-search" />
                                    </span>
                                    <input
                                        id="sidebar_input-search"
                                        type="text"
                                        name="search"
                                        placeholder="Search Here"
                                        className="header_search_input"
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
                        >
                            <NotificationsActiveOutlinedIcon
                                id="sidebar_icon-notificationActive"
                                className={classes.notificationIcon}
                            />
                            {auth && (
                                <ProfileHeader
                                    accountByEmail={accByEmail}
                                    anchorEl={anchorEl}
                                    handleCloseProfile={handleCloseProfile}
                                    handleMenu={handleMenu}
                                    open={open}
                                    openProfile={openProfile}
                                    handleLoginPage={handleLoginPage}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {open === true && (
                            <Slide
                                in={open === true}
                                timeout={500}
                                direction="right"
                            >
                                <MenuOpenIcon
                                    id="sidebar_icon-menuOpen"
                                    style={{ color: "#3C7F9C" }}
                                />
                            </Slide>
                        )}
                    </IconButton>
                </div>
                <div style={{ textAlign: "center" }}>
                    {open === true && (
                        <Zoom
                            in={open === true}
                            timeout={250}
                        >
                            <img
                                id="sidebar_image-logoFull"
                                src={LogoFull}
                                style={{ width: "214px" }}
                            />
                        </Zoom>
                    )}
                    {open === false && (
                        <Zoom
                            in={open === false}
                            timeout={250}
                        >
                            <img
                                id="sidebar_icon-logoSmall"
                                src={LogoSmall}
                                style={{ width: "45px" }}
                            />
                        </Zoom>
                    )}
                </div>
                <Divider />
                <List style={{ height: "100vh" }}>
                    <ListItem
                        button
                        className={classes.listItem}
                        onClick={handleDashboard}
                    >
                        <HomeOutlinedIcon />
                        {open === true && (
                            <Slide
                                in={open === true}
                                timeout={300}
                                direction="left"
                            >
                                <Typography
                                    id="sidebar_item-dashboard"
                                    className={classes.listItem_text}
                                >
                                    Dashboard
                                </Typography>
                            </Slide>
                        )}
                    </ListItem>
                    <ListItem
                        button
                        className={classes.listItem}
                        onClick={handleAccount}
                    >
                        <AccountCircleOutlinedIcon />
                        {open === true && (
                            <Slide
                                in={open === true}
                                timeout={350}
                                direction="left"
                            >
                                <Typography
                                    id="sidebar_item-dashboard"
                                    className={classes.listItem_text}
                                >
                                    Accounts
                                </Typography>
                            </Slide>
                        )}
                    </ListItem>
                    <ListItem
                        button
                        className={classes.listItem}
                    >
                        <DescriptionOutlinedIcon />
                        {open === true && (
                            <Slide
                                in={open === true}
                                timeout={400}
                                direction="left"
                            >
                                <Typography
                                    id="sidebar_item-addDocuments"
                                    className={classes.listItem_text}
                                >
                                    Add Documents</Typography>
                            </Slide>
                        )}
                    </ListItem>
                    <ListItem button className={classes.listItem}>
                        <PersonOutlineOutlinedIcon />
                        {open === true && (
                            <Slide
                                in={open === true}
                                timeout={450}
                                direction="left"
                            >
                                <Typography
                                    id="sidebar_item-addClient"
                                    className={classes.listItem_text}
                                >
                                    Add Client
                                </Typography>
                            </Slide>
                        )}
                    </ListItem>
                    <ListItem button className={classes.listItem}>
                        <PictureInPictureAltOutlinedIcon />
                        {open === true && (
                            <Slide
                                in={open === true}
                                timeout={500}
                                direction="left"
                            >
                                <Typography
                                    id="sidebar_item-template"
                                    className={classes.listItem_text}
                                >
                                    Template
                                </Typography>
                            </Slide>
                        )}
                    </ListItem>
                    <ListItem
                        button
                        className={classes.listItem}
                        onClick={handleProfilePage}
                    >
                        <AssignmentIndOutlinedIcon />
                        {open === true && (
                            <Slide
                                in={open === true}
                                timeout={450}
                                direction="left"
                            >
                                <Typography
                                    id="sidebar_item-addClient"
                                    className={classes.listItem_text}
                                >
                                    Profile Page
                                </Typography>
                            </Slide>
                        )}
                    </ListItem>
                    <ListItem
                        button
                        className={classes.listItem}
                        onClick={handleLoginPage}
                        style={{ position: "absolute", bottom: 0 }}
                    >
                        <ExitToAppIcon />
                        {open === true && (
                            <Zoom
                                in={open === true}
                                timeout={550}
                            >
                                <Typography
                                    id="sidebar_item-logout"
                                    className={classes.listItem_text}

                                >
                                    Logout
                                </Typography>
                            </Zoom>
                        )}
                    </ListItem>
                </List>
            </Drawer>
            {isMobile && (
                <Grid
                    item
                    xs={12}
                >
                    <AppBar
                        position="static"
                        className={classes.header}
                    >
                        <Toolbar>
                            <IconButton
                                id="sidebar_iconButton_menuIcon-mobile"
                                edge="start"
                                className={classes.menuButtonMobile}
                                aria-label="menu"
                                onClick={() => setOpen((prev) => !prev)}
                            >
                                <MenuIcon id="sidebar_icon_menuIcon-mobile" />
                            </IconButton>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
                            >
                                {auth && (
                                    <ProfileHeader
                                        accountByEmail={accByEmail}
                                        anchorEl={anchorEl}
                                        handleCloseProfile={handleCloseProfile}
                                        handleMenu={handleMenu}
                                        open={open}
                                        openProfile={openProfile}
                                        handleLoginPage={handleLoginPage}
                                    />
                                )}
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        id="sidebar_drawer-mobile"
                        anchor="top"
                        open={open}
                        onClose={() => setOpen(false)}
                        disablePortal
                        classes={{
                            root: classes.root,
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            style={{ textAlign: "center", justifyContent: "center", padding: "10px", background: "#3C7F9C", border: "none" }}>
                            <Zoom
                                in={open === true}
                                timeout={250}
                            >
                                <img
                                    id="sidebar_image-logoFull"
                                    src={LogoFull}
                                    style={{ width: "174px" }}
                                />
                            </Zoom>
                        </Grid>
                        <List className={classes.list_wrapper}>
                            <ListItem
                                button
                                className={classes.listItem_mobile}
                                onClick={handleDashboard}
                            >
                                {open === true && (
                                    <Slide
                                        in={open === true}
                                        timeout={300}
                                        direction="left"
                                    >
                                        <Typography
                                            id="sidebar_item-dashboard"
                                            className={classes.listItem_text_mobile}
                                        >
                                            Dashboard
                                        </Typography>
                                    </Slide>
                                )}
                            </ListItem>
                            <Divider className={classes.divider} />
                            <ListItem
                                button
                                className={classes.listItem_mobile}
                                onClick={handleAccount}
                            >
                                {open === true && (
                                    <Slide
                                        in={open === true}
                                        timeout={350}
                                        direction="left"
                                    >
                                        <Typography
                                            id="sidebar_item-dashboard"
                                            className={classes.listItem_text_mobile}
                                        >
                                            Accounts
                                        </Typography>
                                    </Slide>
                                )}
                            </ListItem>
                            <Divider className={classes.divider} />
                            <ListItem
                                button
                                className={classes.listItem_mobile}
                            >
                                {open === true && (
                                    <Slide
                                        in={open === true}
                                        timeout={400}
                                        direction="left"
                                    >
                                        <Typography
                                            id="sidebar_item-addDocuments"
                                            className={classes.listItem_text_mobile}
                                        >
                                            Add Documents
                                        </Typography>
                                    </Slide>
                                )}
                            </ListItem>
                            <Divider className={classes.divider} />
                            <ListItem
                                button
                                className={classes.listItem_mobile}
                            >
                                {open === true && (
                                    <Slide
                                        in={open === true}
                                        timeout={450}
                                        direction="left"
                                    >
                                        <Typography
                                            id="sidebar_item-addClient"
                                            className={classes.listItem_text_mobile}
                                        >
                                            Add Client
                                        </Typography>
                                    </Slide>
                                )}
                            </ListItem>
                            <Divider className={classes.divider} />
                            <ListItem
                                button
                                className={classes.listItem_mobile}
                            >
                                {open === true && (
                                    <Slide
                                        in={open === true}
                                        timeout={500}
                                        direction="left"
                                    >
                                        <Typography
                                            id="sidebar_item-template"
                                            className={classes.listItem_text_mobile}
                                        >
                                            Template
                                        </Typography>
                                    </Slide>
                                )}
                            </ListItem>
                            <Divider className={classes.divider} />
                            <ListItem
                                button
                                className={classes.listItem_mobile}
                                onClick={handleProfilePage}
                            >
                                {open === true && (
                                    <Slide
                                        in={open === true}
                                        timeout={450}
                                        direction="left"
                                    >
                                        <Typography
                                            id="sidebar_item-addClient"
                                            className={classes.listItem_text_mobile}
                                        >
                                            Profile Page
                                        </Typography>
                                    </Slide>
                                )}
                            </ListItem>
                            <Divider className={classes.divider} />
                            <ListItem
                                button
                                className={classes.listItem_mobile}
                                onClick={handleLoginPage}
                            >
                                <ExitToAppIcon style={{ color: "white" }} />
                                {open === true && (
                                    <Zoom
                                        in={open === true}
                                        timeout={550}
                                    >
                                        <Typography
                                            id="sidebar_item-logout"
                                            className={classes.listItem_text_mobile}
                                        >
                                            Logout
                                        </Typography>
                                    </Zoom>
                                )}
                            </ListItem>
                        </List>
                    </Drawer>
                </Grid>
            )}
        </div>
    );
};