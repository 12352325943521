import { configureStore } from '@reduxjs/toolkit';
import accountsSlice from '../slices/accountSlice';
import addressSlice from '../slices/addressSlice';
import snackbarReducer from '../slices/snackbarSlice'

export const store = configureStore({
  reducer: {
    account: accountsSlice,
    snackbar: snackbarReducer,
    address: addressSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch