import {
    createAsyncThunk,
    createSelector,
    createSlice,
    PayloadAction
} from "@reduxjs/toolkit";
import {
    login as loginApi,
    resetPassword as resetPasswordApi,
    createAccount as createAccountApi,
    getAllAccounts as getAccountsApi,
    forgotPasswordRequest as forgotPasswordRequestApi,
    checkOldPassword as checkOldPasswordRequestApi,
    getAccountByEmail as getAccountByEmailApi,
    getSelectedAccountByEmail as getSelectedAccountByEmailApi
} from "../app/easyBusinessApi";
import { RootState } from "../app/rootReducers";
import UserAccount from "../models/userAccount";
import LoginRequest from "../models/login/loginRequest";
import ResetPassword from "../models/login/resetPassword";
import ResetPasswordRequest from "../models/login/resetPasswordRequest";
import AccountModel from "../models/accountModel";
import AccountByEmailModel from "../models/accountByEmailModel";

interface AccountState {
    account: AccountModel | null;
    currentAccountByEmail: AccountByEmailModel | null;
    selectedAccount: AccountByEmailModel | null;
    accounts: UserAccount[];
    isFirstLogin: boolean | null;
    loginFailed: boolean | null;
    createdAccount: boolean | null;
    forgottenPassword: boolean | null;
    accessToken: string;
    checkOldPass: boolean | null;
    passwordChanged: boolean | null;
};

let INIT_ACCOUNT: AccountModel = {
    firstName: "",
    lastName: "",
    email: "",
    cityId: "",
    countryCode: "",
    note: "",
    phone: "",
    role: 0,
    streetId: "",
    streetNumber: "",
    userImgData: ""
};

let initialState: AccountState = {
    account: INIT_ACCOUNT,
    accounts: [],
    selectedAccount: null,
    isFirstLogin: null,
    loginFailed: null,
    createdAccount: null,
    forgottenPassword: null,
    accessToken: "",
    checkOldPass: null,
    passwordChanged: null,
    currentAccountByEmail: null,
};

export const selectAccessToken = createSelector(
    (state: RootState) => state.account.accessToken,
    (token: string) => {
        return token;
    }
);

export const selectAccount = createSelector(
    (state: RootState) => state.account.account,
    (account: AccountModel | null) => {
        return account;
    }
);

export const selectCurrentAccountByEmail = createSelector(
    (state: RootState) => state.account.currentAccountByEmail,
    (account: AccountByEmailModel | null) => {
        return account;
    }
);

export const selectSelectedAccountByEmail = createSelector(
    (state: RootState) => state.account.selectedAccount,
    (account: AccountByEmailModel | null) => {
        return account;
    }
);

export const selectAllAccounts = createSelector(
    (state: RootState) => state.account.accounts,
    (accounts: UserAccount[]) => {
        return accounts;
    }
);

export const selectCreatedAccount = createSelector(
    (state: RootState) => state.account.createdAccount,
    (createdAccount: boolean | null) => {
        return createdAccount;
    }
);

export const selectIsFirstLogin = createSelector(
    (state: RootState) => state.account.isFirstLogin,
    (isFirstLogin: boolean | null) => {
        return isFirstLogin;
    }
);

export const selectLoginFailed = createSelector(
    (state: RootState) => state.account.loginFailed,
    (loginFailed: boolean | null) => {
        return loginFailed;
    }
);

export const selectForgottenPassword = createSelector(
    (state: RootState) => state.account.forgottenPassword,
    (resetPassword: boolean | null) => {
        return resetPassword;
    }
);

export const selectChangedPassword = createSelector(
    (state: RootState) => state.account.passwordChanged,
    (changePassword: boolean | null) => {
        return changePassword;
    }
);

export const selectCheckPassword = createSelector(
    (state: RootState) => state.account.checkOldPass,
    (checkedPass: boolean | null) => {
        return checkedPass;
    }
);

export const login = createAsyncThunk("account/login", async (loginRequest: LoginRequest) => {
    const response = await loginApi(loginRequest);
    return response;
});

export const resetPassword = createAsyncThunk("account/reset-password", async (resetPassword: ResetPassword) => {
    const response = await resetPasswordApi(resetPassword);
    return response;
});

export const checkOldPassword = createAsyncThunk("account/check-old-password", async (request: ResetPasswordRequest) => {
    const response = await checkOldPasswordRequestApi(request);
    return response;
});

export const forgotPasswordRequest = createAsyncThunk("account/reset-password_request", async (forgotPasswordRequest: ResetPasswordRequest) => {
    const response = await forgotPasswordRequestApi(forgotPasswordRequest);
    return response;
});

export const getAccounts = createAsyncThunk("account/get_accounts", async () => {
    const response = await getAccountsApi();
    return response;
});

export const getAccountByEmail = createAsyncThunk("address/get_account_by_email", async (email: string) => {
    const response = await getAccountByEmailApi(email);
    return response;
});

export const getSelectedAccountByEmail = createAsyncThunk("address/get_selected_account_by_email", async (email: string) => {
    const response = await getSelectedAccountByEmailApi(email);
    return response;
});

export const createAccount = createAsyncThunk("account/create_account", async (account: UserAccount) => {
    const response = await createAccountApi(account);
    return response;
});

export const resetNewAccount = createAsyncThunk("documents/reset_save_new_account", async () => {
    return null;
});

export const resetSelectedAccount = createAsyncThunk("documents/reset_selected_account", async () => {
    return null;
});

export const resetAccountByEmail = createAsyncThunk("documents/reset_account_by_email", async () => {
    return null;
});

export const resetIsFirstLogin = createAsyncThunk("account/resetIsFirstLogin", async () => {
    return false;
});

export const resetCreateAccount = createAsyncThunk("account/reset_create_account", async () => {
    return null;
});

export const resetLoginFailed = createAsyncThunk("account/reset_login_failed", async () => {
    return null;
});

export const resetForgottenPassword = createAsyncThunk("account/reset_reset_password_request", async () => {
    return null;
});

export const resetChangePassword = createAsyncThunk("account/reset_change_password", async () => {
    return null;
});

export const resetIsCheckedPass = createAsyncThunk("account/reset_is_checked_password", async () => {
    return null;
});


export const logout = createAsyncThunk("account/logout", async () => {
    return INIT_ACCOUNT;
});

const accountSlice = createSlice({
    name: "accounts",
    initialState: initialState,
    reducers: {
        updateAccountList(state, action: PayloadAction<AccountState>) {

            // state.accessToken = action.payload.accessToken
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, { payload }) => {
                var accessToken = payload.data.accessToken;
                localStorage.setItem('accessToken', accessToken);
                console.log(accessToken);
                state.accessToken = accessToken;
                var response = payload.data;
                if (response.isFirstLogin) {
                    state.isFirstLogin = true;
                    state.loginFailed = true;
                    var jwt = response.accessToken;
                    var decode = require('jwt-claims');
                    var claims = decode(jwt);
                    state.account = {
                        firstName: claims.given_name,
                        lastName: claims.family_name,
                        email: claims.email,
                        cityId: "",
                        countryCode: "",
                        note: "",
                        phone: "",
                        role: 0,
                        streetId: "",
                        streetNumber: "",
                        userImgData: ""
                    }
                } else {
                    if (response.accessToken !== null) {
                        var jwt = response.accessToken;
                        var decode = require('jwt-claims');
                        var claims = decode(jwt);
                        state.account = {
                            firstName: claims.given_name,
                            lastName: claims.family_name,
                            email: claims.email,
                            cityId: "",
                            countryCode: claims.CountryCode,
                            note: "",
                            phone: claims.Number,
                            role: 0,
                            streetId: "",
                            streetNumber: "",
                            userImgData: ""
                        }
                        state.loginFailed = null;
                    }
                }
            })
            .addCase(login.rejected, (state, { }) => {
                state.loginFailed = false;
            })
            .addCase(resetIsFirstLogin.fulfilled, (state, { }) => {
                state.isFirstLogin = null;
            })
            .addCase(resetForgottenPassword.fulfilled, (state, { }) => {
                state.forgottenPassword = null;
            })
            .addCase(forgotPasswordRequest.fulfilled, (state, { }) => {
                state.forgottenPassword = true;
            })
            .addCase(forgotPasswordRequest.rejected, (state, { }) => {
                state.forgottenPassword = false;
            })
            .addCase(resetPassword.fulfilled, (state, { }) => {
                state.forgottenPassword = null;
                state.passwordChanged = true;
                state.checkOldPass = null;
            })
            .addCase(resetPassword.rejected, (state, { }) => {
                state.passwordChanged = false;
            })
            .addCase(resetChangePassword.fulfilled, (state, { }) => {
                state.passwordChanged = null;
            })
            .addCase(createAccount.fulfilled, (state, { }) => {
                state.createdAccount = true;
            })
            .addCase(createAccount.rejected, (state, { }) => {
                // state.account = null;
                state.createdAccount = false;
            })
            .addCase(getAccounts.fulfilled, (state, { payload }) => {
                state.accounts = payload.data.result;
                console.log("getAccountByEmail", payload.data.result)
            })
            .addCase(getAccounts.rejected, (state, { }) => {
                state.accounts = [];
            })
            .addCase(getAccountByEmail.fulfilled, (state, { payload }) => {
                state.currentAccountByEmail = payload.data;
                console.log("getAccountByEmail", payload)
            })
            .addCase(getAccountByEmail.rejected, (state, { }) => {
                state.currentAccountByEmail = null;
            })
            .addCase(getSelectedAccountByEmail.fulfilled, (state, { payload }) => {
                state.selectedAccount = payload.data;
                console.log("getAccountByEmail", payload)
            })
            .addCase(getSelectedAccountByEmail.rejected, (state, { }) => {
                state.selectedAccount = null;
            })
            .addCase(resetNewAccount.fulfilled, (state, { }) => {
                state.account = null;
            })
            .addCase(resetCreateAccount.fulfilled, (state, { }) => {
                state.createdAccount = null;
            })
            .addCase(resetSelectedAccount.fulfilled, (state, { payload }) => {
                state.selectedAccount = null;
            })
            .addCase(resetLoginFailed.fulfilled, (state, { }) => {
                state.loginFailed = null;
            })
            .addCase(checkOldPassword.fulfilled, (state, { }) => {
                state.checkOldPass = true;
            })
            .addCase(checkOldPassword.rejected, (state, { }) => {
                state.checkOldPass = false;
            })
            .addCase(logout.fulfilled, (state, { payload }) => {
                state.account = payload;
                state.isFirstLogin = false;
            })
    },
});

export default accountSlice.reducer;