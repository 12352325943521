import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
    Grid,
    IconButton,
    Typography,
    Zoom
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Logo from "../../../images/easybusinessLogo.png";
import {
    Button,
    Modal,
    ModalHeader
} from "react-bootstrap";
import {
    Visibility,
    VisibilityOff
} from "@material-ui/icons";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ResetPasswordRequest from "../../../models/login/resetPassword";
import { resetPassword } from "../../../slices/accountSlice";
import { useAppDispatch } from "../../../app/hooks";
import { dashboardPage as dashboardPageRoute } from '../../../app/routes';
import { useHistory } from 'react-router-dom';
import './resetPasswordModal.css';

interface Props {
    isOpen: boolean;
    handleClose: any;
    account: any;
};

const useStyles = makeStyles({
    title: {
        fontWeight: 700,
        fontSize: "35px",
        lineHeight: "46px",
        color: "#8F8F8F"
    },
    modalHeader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderBottom: "none"
    }
});

const ResetPasswordModal = ({
    handleClose,
    isOpen,
    account
}: Props) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const history = useHistory();

    const [password, setPassword] = useState({
        passwordNew: '',
        passwordConfirm: ''
    });

    const INIT_RESET_PASSWORD_REQUEST: ResetPasswordRequest = {
        code: "",
        email: "",
        password: "",
        oldPassword: ""
    };

    const [resetPasswordRequest, setResetPasswordRequest] = useState<ResetPasswordRequest>(INIT_RESET_PASSWORD_REQUEST);

    const [showPasswordNew, setShowPasswordNew] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const [validLength, setValidLength] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [upperCase, setUpperCase] = useState(false);
    const [specialChar, setSpecialChar] = useState(false);
    const [match, setMatch] = useState(false);
    const [requiredLength, setRequiredLength] = useState(8);
    const [validLenghtIcon, setValidLenghtIcon] = useState(false);
    const [validHasNumberIcon, setValidHasNumbertIcon] = useState(false);
    const [validUpperCaseIcon, setValidUpperCaseIcon] = useState(false);
    const [validSpecialCharIcon, setValidSpecialCharIcon] = useState(false);
    const [validMatchIcon, setValidMatchIcon] = useState(false);

    const handleMouseDownPasswordNew = () => setShowPasswordNew(!showPasswordNew);
    const handleMouseDownPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm);

    const inputChange: (event: React.ChangeEvent<HTMLInputElement>) => void = (event) => {
        const { value, name } = event.target;
        const email = account.email;
        setPassword({
            ...password,
            [name]: value
        })
        var newResetPasswordRequest = { ...resetPasswordRequest };
        if (name === "passwordConfirm") {
            newResetPasswordRequest.password = value;
            newResetPasswordRequest.email = account.email;
        }
        setResetPasswordRequest(newResetPasswordRequest);
    };

    const togglePasswordNew = () => {
        setShowPasswordNew((showPasswordNew) => !showPasswordNew);
    };

    const togglePasswordConfirm = () => {
        setShowPasswordConfirm((showPasswordConfirm) => !showPasswordConfirm);
    };

    const handleDashboard = () => {
        dispatch(resetPassword(resetPasswordRequest));
        history.push(dashboardPageRoute());
    };

    useEffect(() => {
        setValidLength(password.passwordNew.length >= requiredLength ? true : false);
        setValidLenghtIcon(password.passwordNew.length >= requiredLength ? true : false);
        setUpperCase(password.passwordNew.toLowerCase() !== password.passwordNew);
        setValidUpperCaseIcon(password.passwordNew.toLowerCase() !== password.passwordNew);
        setHasNumber(/\d/.test(password.passwordNew));
        setValidHasNumbertIcon(/\d/.test(password.passwordNew));
        setMatch(!!password.passwordNew && password.passwordNew === password.passwordConfirm);
        setValidMatchIcon(!!password.passwordNew && password.passwordNew === password.passwordConfirm);
        setSpecialChar(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password.passwordNew));
        setValidSpecialCharIcon(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password.passwordNew));
    }, [password, requiredLength]);

    return (
        <Modal
            aria-labelledby="resetPasswordModal"
            aria-hidden="true"
            tabindex='-1'
            id="setPassword_modal"
            show={isOpen}
            onHide={handleClose}
            className="text-center"
            centered
        >
            <ModalHeader
                id="setPassword_modal-header"
                className={classes.modalHeader}
            >
                <img
                    src={Logo}
                    alt=""
                    className="resetPasswordlogo"
                />
            </ModalHeader>
            <Modal.Body
                id="resetPassword_modal-body"
                className="resetPassword_modal_body"
            >
                <Grid item md={12}>
                    <Typography
                        id="resetPassword_modal-title"
                        className={classes.title}
                    >
                        Reset password
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={12}
                >
                    <div className="form-group mt-4">
                        <div className="resetPasswordform_input">
                            <input
                                id="resetPassword_input-passwordNew"
                                type={showPasswordNew ? "text" : "password"}
                                name="passwordNew"
                                placeholder="New Password"
                                className="resetPasswordinput"
                                onChange={(e) => inputChange(e)}
                            />
                            <span className="resetPasswordicon_visibility_wrapper">
                                <IconButton
                                    id="resetPassword_icon-passwordNew"
                                    name="passwordNewIcon"
                                    className="resetPasswordicon_visibility"
                                    aria-label="toggle password visibility"
                                    onClick={togglePasswordNew}
                                    onMouseDown={handleMouseDownPasswordNew}
                                >
                                    {showPasswordNew ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </span>
                        </div>
                    </div>
                </Grid>
                <Grid item md={12} >
                    <div className="form-group mt-4">
                        <div className="resetPasswordform_input">
                            <input
                                id="resetPassword_input-passwordConfirm"
                                type={showPasswordConfirm ? "text" : "password"}
                                name="passwordConfirm"
                                placeholder="Confirm New Password"
                                className="resetPasswordinput"
                                onChange={(e) => inputChange(e)}
                                value={resetPasswordRequest.password}
                                disabled={!specialChar}
                            />
                            <span className="resetPasswordicon_visibility_wrapper">
                                <IconButton
                                    id="resetPassword_icon-passwordConfirm"
                                    name="passwordConfirmIcon"
                                    className="resetPasswordicon_visibility"
                                    aria-label="toggle password visibility"
                                    onClick={togglePasswordConfirm}
                                    onMouseDown={handleMouseDownPasswordConfirm}
                                >
                                    {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </span>
                        </div>
                    </div>
                </Grid>
                <Grid
                    item
                    md={12}
                    style={{ padding: "20px", marginTop: "-20px" }}
                >
                    <div className="passwordValidation_wrapper">
                        {validLenghtIcon ?
                            <CheckCircleIcon
                                id="resetPassword_icon-passwordValidationSuccess"
                                fontSize="small"
                                className="passwordValidationSuccess_icon"
                            />
                            :
                            <RadioButtonUncheckedIcon
                                id="resetPassword_icon-passwordValidationError"
                                fontSize="small"
                                className="passwordValidationError_icon"
                            />
                        }
                        {validLength ?
                            <Typography
                                id="resetPassword_validationSuccess-minimumCharacters"
                                className="passwordValidationSuccess"
                            >
                                minimum 8 characters
                            </Typography>
                            :
                            <Typography
                                id="resetPassword_validationError-minimumCharacters"
                                className="passwordValidationError"
                            >
                                minimum 8 characters
                            </Typography>
                        }
                    </div>
                    <div className="passwordValidation_wrapper">
                        {validUpperCaseIcon ?
                            <CheckCircleIcon
                                id="resetPassword_icon-passwordValidationSuccess"
                                fontSize="small"
                                className="passwordValidationSuccess_icon"
                            />
                            :
                            <RadioButtonUncheckedIcon
                                id="resetPassword_icon-passwordValidationError"
                                fontSize="small"
                                className="passwordValidationError_icon"
                            />
                        }
                        {upperCase ?
                            <Typography
                                id="resetPassword_validationSuccess-capitalLetter"
                                className="passwordValidationSuccess"
                            >minimum 1 a capital (uppercase) letter
                            </Typography>
                            :
                            <Typography
                                id="resetPassword_validationError-capitalLetter"
                                className="passwordValidationError"
                            >
                                minimum 1 a capital (uppercase) letter
                            </Typography>
                        }
                    </div>
                    <div className="passwordValidation_wrapper">
                        {validHasNumberIcon ?
                            <CheckCircleIcon
                                id="resetPassword_icon-passwordValidationSuccess"
                                fontSize="small"
                                className="passwordValidationSuccess_icon"
                            />
                            :
                            <RadioButtonUncheckedIcon
                                id="resetPassword_icon-passwordValidationError"
                                fontSize="small"
                                className="passwordValidationError_icon"
                            />
                        }
                        {hasNumber ?
                            <Typography
                                id="resetPassword_validationSuccess-minimumNumber"
                                className="passwordValidationSuccess"
                            >
                                minimum 1 number
                            </Typography>
                            :
                            <Typography
                                id="resetPassword_validationError-minimumNumber"
                                className="passwordValidationError"
                            >
                                minimum 1 number
                            </Typography>
                        }
                    </div>
                    <div className="passwordValidation_wrapper">
                        {validSpecialCharIcon ?
                            <CheckCircleIcon
                                id="resetPassword_icon-passwordValidationSuccess"
                                fontSize="small"
                                className="passwordValidationSuccess_icon"
                            />
                            :
                            <RadioButtonUncheckedIcon
                                id="resetPassword_icon-passwordValidationError"
                                fontSize="small"
                                className="passwordValidationError_icon"
                            />
                        }
                        {specialChar ?
                            <Typography
                                id="resetPassword_validationSuccess-specialCharacter"
                                className="passwordValidationSuccess"
                            >
                                minimum 1 special character
                            </Typography>
                            :
                            <Typography
                                id="resetPassword_validationError-specialCharacter"
                                className="passwordValidationError"
                            >
                                minimum 1 special character
                            </Typography>
                        }
                    </div>
                    <div className="passwordValidation_wrapper">
                        {validMatchIcon ?
                            <CheckCircleIcon
                                id="resetPassword_icon-passwordValidationSuccess"
                                fontSize="small"
                                className="passwordValidationSuccess_icon"
                            />
                            :
                            <RadioButtonUncheckedIcon
                                id="resetPassword_icon-passwordValidationError"
                                fontSize="small"
                                className="passwordValidationError_icon"
                            />
                        }
                        {match ?
                            <Typography
                                id="resetPassword_validationSuccess-passwordConfirm"
                                className="passwordValidationSuccess"
                            >
                                password confirmed
                            </Typography>
                            :
                            <Typography
                                id="resetPassword_validationError-passwordConfirm"
                                className="passwordValidationError"
                            >
                                password confirmed
                            </Typography>
                        }
                    </div>
                </Grid>
                <motion.div className="d-grid mt-2 p-4">
                    {validMatchIcon === true && (
                        <Zoom in={validMatchIcon === true} timeout={250}>
                            <Button
                                id="resetPassword_buttonEnabled-save"
                                type="submit"
                                className="resetPasswordsave_button_success"
                                onClick={handleDashboard}
                            >
                                Save
                            </Button>
                        </Zoom>
                    )}
                    {validMatchIcon === false && (
                        <Zoom in={validMatchIcon === false} timeout={250}>
                            <Button
                                id="resetPassword_buttonDisabled-save"
                                type="submit"
                                className="resetPasswordsave_button_error"
                                onClick={handleDashboard}
                                disabled={!validMatchIcon}
                            >
                                Save
                            </Button>
                        </Zoom>
                    )}
                </motion.div>
            </Modal.Body>
        </Modal>
    );
};

export default ResetPasswordModal;