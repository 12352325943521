import React from "react";
import {
    Grid,
    Typography,
    createStyles,
    makeStyles,
    Theme,
    Zoom,
    Fade,
} from "@material-ui/core";
import { Modal } from "react-bootstrap";
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { red } from "@material-ui/core/colors";
import './tableViewModal.css';

interface Props {
    isOpen: boolean;
    handleClose: any;
    selectedAccount: any;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            backgroundColor: red[500],
            width: "200px",
            height: "200px",
            borderRadius: "5%"
        },
        content: {
            color: "#696969"
        },
        title: {
            color: "#B0B0B0"
        },
        credentials: {
            color: "#696969"
        }
    }),
);

const TableViewModal = ({
    handleClose,
    isOpen,
    selectedAccount
}: Props) => {
    const classes = useStyles();
    const countryCode = selectedAccount?.phone.countryCode;
    const number = selectedAccount?.phone.number;
    const img = selectedAccount?.userImageData200x200;

    return (
        <div className="container">
            <Modal
                show={isOpen}
                onHide={handleClose}
                centered
                id="tableView_modal"
            >
                <Modal.Header
                    id="tableView_modal-header"
                    className="tableView_header"
                >
                    <Typography variant="h5">Add/update</Typography>
                </Modal.Header>
                <Modal.Body style={{ padding: 0, background: "#E6ECEF" }}>
                    <Grid container style={{ padding: "20px" }}>
                        <Grid
                            item
                            md={6}
                        >
                            <Fade in={isOpen} timeout={1000}>
                                <img
                                    id="information-card_avatar"
                                    src={`data:image/jpeg;base64,${img}`}
                                    className={classes.avatar}
                                />
                            </Fade>
                        </Grid>
                        <Grid
                            item
                            md={6}
                            className="tableView_personal_wrapp"
                        >
                            <Fade
                                in={isOpen}
                                timeout={1000}
                            >
                                <div >
                                    <Typography
                                        variant="h4"
                                        className={classes.credentials}
                                    >
                                        {selectedAccount?.firstName}
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        className={classes.credentials}
                                    >
                                        {selectedAccount?.lastName}
                                    </Typography>
                                    <Typography variant="h6">Kninska 151a, Novi Sad</Typography>
                                </div>
                            </Fade>
                        </Grid>
                    </Grid >
                    <Grid
                        container
                        className="tableView_grid"
                        style={{ paddingLeft: "20px", paddingRight: "20px" }}
                    >
                        <Zoom in={isOpen} timeout={200}>
                            <Grid container>
                                <Grid item md={12}>
                                    <label className="tableView_label_title">Email:</label>
                                </Grid>
                                <Grid item md={12}>
                                    <label className="tableView_label_content">{selectedAccount?.email}</label>
                                </Grid>
                            </Grid>
                        </Zoom>
                        <Zoom
                            in={isOpen}
                            timeout={600}
                        >
                            <Grid
                                container
                                style={{ marginTop: "10px" }}
                            >
                                <Grid item md={12}>
                                    <label className="tableView_label_title">Phone:</label>
                                </Grid>
                                <Grid item md={12}>
                                    <label className="tableView_label_content">{countryCode}{number}</label>
                                </Grid>
                            </Grid>
                        </Zoom>
                        <Zoom
                            in={isOpen}
                            timeout={1000}
                        >
                            <Grid style={{ marginTop: "10px" }}>
                                <Grid item md={12}>
                                    <label className="tableView_label_title">Role:</label>
                                </Grid>
                                <Grid item md={12}>
                                    <label className="tableView_label_content">{selectedAccount?.role}</label>
                                </Grid>
                            </Grid>
                        </Zoom>
                    </Grid>
                    <Grid
                        container
                        className="tableView_grid"
                        style={{ paddingLeft: "20px", paddingRight: "20px" }}
                    >
                        <Fade in={isOpen} timeout={1400}>
                            <Grid
                                item
                                md={12}
                                className="tableView_span"
                            >
                                <span>Note</span>
                                <textarea
                                    name="search"
                                    className="tableView_input"
                                    rows={5}
                                />
                            </Grid>
                        </Fade>
                    </Grid>
                    <Grid
                        container
                        className="tableView_grid"
                    >
                        <Grid
                            item
                            md={12}
                            className="tableView_buttons_wrapper"
                        >
                            <CreateOutlinedIcon className="tableView_edit_button" />
                            <DeleteOutlineOutlinedIcon className="tableView_delete_button" />
                        </Grid>
                    </Grid>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default TableViewModal;