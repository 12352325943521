import React, { useState } from 'react';
import {
    Button,
    Grid,
    Typography,
    Zoom
} from '@material-ui/core';
import { useAppDispatch } from '../../app/hooks';
import SnackbarModel from '../../models/snackbarModel';
import { setSnackbar } from '../../slices/snackbarSlice';
import PublishIcon from '@material-ui/icons/Publish';
import './uploadButton.css';

interface Props {
    setFileBase64String: any;
    onImageChange: any;
    selectedImage: any;
    setSelectedImage: any;
}

export default function UploadButton({
    setFileBase64String,
    onImageChange,
    selectedImage,
    setSelectedImage
}: Props) {

    function handleUpload(e: any) {
        onImageChange(e);
    };

    function handleChange(e: any) {
        setSelectedImage(undefined)
        onImageChange(e);
    };

    function deleteFile() {
        setSelectedImage(undefined);
        setFileBase64String("");
    };

    return (
        <>
            <Grid>
                <input
                    name="uploadButton_input"
                    id="contained-button-file"
                    type="file"
                    accept="image/jpg, image/jpeg, image/png"
                    onChange={(e) => onImageChange(e)}
                    style={{ display: "none" }}
                />
                {selectedImage === undefined && (
                    <div className="uploadButton_upload_container">
                        <label
                            htmlFor="contained-button-file"
                            className="uploadButton_label_publish"
                        >
                            <Zoom
                                in={selectedImage === undefined}
                                timeout={400}
                            >
                                <PublishIcon
                                    name="publishIcon"
                                    onClick={handleUpload}
                                    className="uploadButton_icon_publish"
                                />
                            </Zoom>
                            <Zoom
                                in={selectedImage === undefined}
                                timeout={500}
                            >
                                <Typography className="uploadButton_text_publish">Upload</Typography>
                            </Zoom>
                        </label>
                    </div>
                )}
            </Grid>
            {selectedImage && (
                <div>
                    <img
                        src={URL.createObjectURL(selectedImage)}
                        className="uploadButton_image"
                        alt="Thumb"
                    />
                    <Grid item md={12} style={{ padding: "15px" }}>
                        <Zoom in={selectedImage} timeout={300}>
                            <Button
                                onClick={deleteFile}
                                className="uploadButton_button_remove-image" >
                                Remove
                            </Button>
                        </Zoom>
                        <Zoom
                            in={selectedImage}
                            timeout={300}
                        >
                            <Button
                                onClick={handleChange}
                                name="change_button"
                                className="uploadButton_button_edit-image"
                            >
                                Change
                            </Button>
                        </Zoom>
                    </Grid>
                </div>
            )
            }
        </>
    );
};