import React from 'react';
import {
    createStyles,
    Card,
    CardContent,
    CardHeader,
    Divider,
    IconButton,
    makeStyles,
    Menu,
    MenuItem,
    Theme,
    Typography,
    Tooltip
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ukIcon from '../../images/uk.png';
import rsIcon from '../../images/serbia.png'
import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';
import TranslateIcon from '@material-ui/icons/Translate';
import EuroIcon from '@material-ui/icons/Euro';
import { red } from '@material-ui/core/colors';
import { useSelector } from 'react-redux';
import { selectAccount } from '../../slices/accountSlice';
import './profileHeader.css';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },
        notificationIcon: {
            color: "#CA9100",
            marginRight: "10px"
        },
        toolbar_mobile: {
            [theme.breakpoints.down("xs")]: {
                display: "none"
            },
        },
        appbar_mobile: {
            [theme.breakpoints.up("md")]: {
                display: "none"
            },
            [theme.breakpoints.up("lg")]: {
                display: "none"
            },
            [theme.breakpoints.up("sm")]: {
                display: "none"
            },
            [theme.breakpoints.up("xs")]: {
                width: "100%"
            },
        },
        list_wrapper: {
            background: "#3C7F9C"
        },
        divider: {
            background: "#ffff"
        },
        header: {
            background: "#EFEFEF"
        },
        avatar: {
            backgroundColor: red[500],
            height: "50px",
            width: "50px",
            borderRadius: "50%"
        },
        menuItem_text: {
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "20px",
            marginLeft: "5px",
            color: "#565656"
        },
        menuItem_icon: {
            color: "#565656"
        }
    }),
);

interface Props {
    handleMenu: any;
    anchorEl: any;
    openProfile: any;
    handleCloseProfile: any;
    open: any;
    handleLoginPage: any;
    accountByEmail: any;
};

export default function ProfileHeader({
    anchorEl,
    handleCloseProfile,
    handleMenu,
    open,
    openProfile,
    handleLoginPage,
    accountByEmail
}: Props) {
    const classes = useStyles();
    const account = useSelector(selectAccount);

    const lngs1 = [
        { nativeName: 'en', className: 'language_switch_header', icon: ukIcon },
        { nativeName: 'rs', className: 'language_switch_header', icon: rsIcon }
    ];

    return (
        <div id="profileHeader">
            <IconButton onClick={handleMenu}>
                <img
                    className={classes.avatar}
                    src={`data:image/*;base64,${accountByEmail?.userImageData200x200}`}
                />
            </IconButton>
            <Card>
                <Menu
                    id="profile-header_menu"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={openProfile}
                    onClose={handleCloseProfile}
                    className="profileHeader_menu"
                >
                    <CardHeader
                        id="profile-header_card-header"
                        avatar={
                            <img
                                className={classes.avatar}
                                src={`data:image/jpeg;base64,${accountByEmail?.userImageData200x200}`}
                            />
                        }
                        title={account?.firstName + ' ' + account?.lastName}
                        subheader={account?.email}
                    />
                    <CardContent
                        id="profile-header_card-content"
                        className="profileHeader_content"
                    >
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}>
                            <EuroIcon
                                id="profile-header_icon_euro-icon"
                                className="profileHeader_icon_valute"
                            />
                            <Typography
                                id="profile-header_text_amount"
                                variant='h3'
                                className="profileHeader_text_amount"
                            >
                                114,658
                            </Typography>
                        </div>
                    </CardContent>
                    <MenuItem id="profile-header_menu-item_settings">
                        <SettingsIcon
                            id="profile-header_icon_settings"
                            className={classes.menuItem_icon}
                        />
                        <Typography
                            id="profile-header_text_settings"
                            className={classes.menuItem_text}
                        >
                            Settings
                        </Typography>
                    </MenuItem>
                    <Divider className={classes.divider} />
                    <MenuItem id="profileHeader_menu-item_profile">
                        <PersonIcon
                            id="profile-header_icon_person"
                            className={classes.menuItem_icon}
                        />
                        <Typography
                            id="profile-header_text_profile"
                            className={classes.menuItem_text}
                        >
                            Profile
                        </Typography>
                    </MenuItem>
                    <Divider className={classes.divider} />
                    <MenuItem id="profileHeader_menu-item_language">
                        <TranslateIcon
                            id="profile-header_icon_language"
                            className={classes.menuItem_icon}
                        />
                        <Typography
                            id="profile-header_text_language"
                            className={classes.menuItem_text}
                        >
                            Language:
                        </Typography>
                        {lngs1.map((lng) => (
                            <Tooltip
                                id="profile-header_tooltip_language"
                                key={lng.nativeName}
                                title={lng.nativeName === 'en' ? 'UK' : 'RS'}
                                placement="bottom"
                            >
                                <button
                                    id="profile-header_button_language"
                                    className={lng.className}
                                    key={lng.nativeName}
                                    type="submit"
                                >
                                    <img
                                        id="profile-header_img_language"
                                        key={lng.nativeName}
                                        src={lng.icon}
                                        alt={lng.nativeName}
                                    />
                                </button>
                            </Tooltip>
                        ))}
                    </MenuItem>
                    <Divider className="profileHeader_divider" />
                    <MenuItem
                        id="profileHeader_menu-item_logout"
                        onClick={handleLoginPage}
                    >
                        <ExitToAppIcon
                            id="profile-header_icon_logout"
                            className={classes.menuItem_icon}
                        />
                        <Typography
                            id="profile-header_text_logout"
                            className={classes.menuItem_text}
                        >
                            Log out
                        </Typography>
                    </MenuItem>
                </Menu>
            </Card>
        </div>
    );
};