import React, { useEffect, useState } from "react";
import {
    Grid,
    Tooltip,
    Typography
} from "@material-ui/core";
import {
    Form,
    Modal
} from "react-bootstrap";
import {
    resetNewAccount,
    createAccount,
    selectCreatedAccount,
    resetCreateAccount,
    getAccounts,
    selectAllAccounts,
} from '../../../slices/accountSlice';
import { setSnackbar } from "../../../slices/snackbarSlice";
import SnackbarModel from "../../../models/snackbarModel";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../app/hooks";
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import UserAccount from "../../../models/userAccount";
import { motion } from "framer-motion";
import {
    getAllStreetsByCity,
    resetStreets,
    selectCities,
    selectStreetsByCity
} from "../../../slices/addressSlice";
import serbiaFlag from '../../../images/serbiaFlag.png';
import ReactSelect from "react-select";
import ButtonUpload from "../../../components/uploadButton/uploadButton";
import './addNewAccountModal.css';

interface Props {
    isOpen: boolean;
    handleClose: any;
};

const AddNewAccountModal = ({
    handleClose,
    isOpen
}: Props) => {
    const dispatch = useAppDispatch();

    const createdAccount = useSelector(selectCreatedAccount);
    const cities = useSelector(selectCities);
    const streets = useSelector(selectStreetsByCity);
    const accounts = useSelector(selectAllAccounts);
    const roles = [
        {
            label: "Admin",
            value: 2,
            name: "role"
        },
        {
            label: "Operater",
            value: 3,
            name: "role"
        },
    ];

    const countryCodeOptions = [
        { value: "+381", name: "countryCode", label: <div><img src={serbiaFlag} height="15px" width="20px" style={{ marginRight: "3px" }} />+381</div> }
    ];

    interface citiesSelectOption {
        value: string;
        label: string;
        name: string;
    };

    interface streetsSelectOption {
        value: string;
        label: string;
        name: string;
    };

    const getCitiesOptions = (): citiesSelectOption[] => {
        var options: citiesSelectOption[] = [];

        cities.map((city) => {
            var option: citiesSelectOption = {
                value: city.id,
                label: city.name,
                name: "city"
            };
            options.push(option);
        });
        return options;
    };

    const getStreetsOptions = (): streetsSelectOption[] => {
        var options: streetsSelectOption[] = [];

        streets.map((street) => {
            var option: streetsSelectOption = {
                value: street.id,
                label: street.name,
                name: "street",
            };
            options.push(option);
        });
        return options;
    };

    const INIT_ACCOUNT: UserAccount = {
        firstName: "",
        lastName: "",
        email: "",
        cityId: "",
        note: "",
        phone: "",
        countryCode: "",
        role: 0,
        active: false,
        locked: false,
        streetId: "",
        streetNumber: "",
        userImgData: "",
        userImageData200x200: "",
        userImageData50x50: ""
    };

    const [validateNewAccount, setValidateNewAccount] = useState(false);
    const [newAccount, setNewAccount] = useState<UserAccount>(INIT_ACCOUNT);
    const [fileBase64String, setFileBase64String] = useState("");
    const [selectedImage, setSelectedImage] = useState();

    const handleInputChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        const updateAccount = { ...newAccount };
        if (name === "firstName") {
            updateAccount.firstName = value;
        }
        else if (name === "lastName") {
            updateAccount.lastName = value;
        }
        else if (name === "email") {
            updateAccount.email = value;
        }
        else if (name === "phone") {
            updateAccount.phone = value;
        }
        else if (name === "note") {
            updateAccount.note = value;
        }
        else if (name === "streetNumber") {
            updateAccount.streetNumber = value
        }
        setNewAccount(updateAccount);
    };

    const handleSelectChange = (e: any) => {
        const name = e.name;
        const value = e.value;
        const label = e.label;
        const updateAccount = { ...newAccount }
        if (name === "countryCode") {
            updateAccount.countryCode = value;
        }
        if (name === "city") {
            updateAccount.cityId = value as string;
            dispatch(getAllStreetsByCity(updateAccount.cityId));
        }
        if (name === "street") {
            updateAccount.streetId = value;
        }
        if (name === "role") {
            updateAccount.role = value;
        }
        setNewAccount(updateAccount);
    };

    const handleSubmitFields = (e: any) => {
        e.preventDefault();
        const name = e.target.name;
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            switch (name) {
                case "addNewAccountForm":
                    setValidateNewAccount(true);
                    break;
            }
        };
        if (form.checkValidity() !== false) {

        }
    };

    const encodeFileBase64 = (file: any) => {
        var reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
            reader.onload = () => {
                var Base64 = reader.result as string;
                setFileBase64String(Base64);
                const updateImg = { ...newAccount }
                updateImg.userImgData = Base64;
                setNewAccount(updateImg);
            };
            reader.onerror = (error) => {
                console.log("error", error);
            }
        }
    };

    const onImageChange = (e: any) => {
        const files = e.target.files;
        console.log("files", files)
        if (files.size > 2000000) {
            const snackbar: SnackbarModel = {
                message: "Error uploading image. The file size is too large",
                severity: "error",
                show: true,
            }
            dispatch(setSnackbar(snackbar));
        }
        else {
            setSelectedImage(e.target.files[0]);
            encodeFileBase64(e.target.files[0]);
        }
    };

    const handleSaveNewAccount = () => {
        dispatch(createAccount(newAccount));
    };

    const clearSaveNewAccountField = () => {
        const updateAccount: UserAccount = {
            firstName: "",
            lastName: "",
            email: "",
            cityId: "",
            note: "",
            countryCode: "",
            phone: "",
            role: 0,
            active: false,
            locked: false,
            streetId: "",
            streetNumber: "",
            userImgData: "",
            userImageData200x200: "",
            userImageData50x50: ""
        };
        dispatch(resetStreets());
        setNewAccount(updateAccount);
    };

    useEffect(() => {
        if (createdAccount) {
            const snackbar: SnackbarModel = {
                message: "You have successfully added a new account",
                severity: "success",
                show: true,
            }
            dispatch(setSnackbar(snackbar));
            clearSaveNewAccountField();
            setValidateNewAccount(false);
            dispatch(resetCreateAccount());
            setSelectedImage(undefined);
        } else {
            if (createdAccount !== null) {
                const snackbar: SnackbarModel = {
                    message: "Creating an account failed, please check the entered data and try again.",
                    severity: "error",
                    show: true,
                }
                dispatch(setSnackbar(snackbar));
            }
        }
        dispatch(getAccounts());
    }, [createdAccount]);

    return (
        <Modal
            show={isOpen}
            onHide={handleClose}
            className="text-center"
            centered
            size="lg"
            id="addNewAccount_modal"
        >
            <Modal.Header
                id="addNewAccount_modal-header"
                className="addNewAccountModal_header"
            >
                <Typography variant="h5">Add/update</Typography>
            </Modal.Header>
            <Modal.Body
                id="addNewAccount_modal-body"
                className="addNewAccount_modal_body"
            >
                <Form
                    id="addNewAccount_modal-form"
                    noValidate
                    validated={validateNewAccount}
                    onSubmit={handleSubmitFields}
                    name="addNewAccountForm"
                >
                    <Grid container>
                        <Grid
                            item
                            md={4}
                            sm={12}
                            xs={12}
                            className="addNewAccount_upload_wrapper"
                        >
                            <Grid>
                                <ButtonUpload
                                    setFileBase64String={setFileBase64String}
                                    onImageChange={onImageChange}
                                    selectedImage={selectedImage}
                                    setSelectedImage={setSelectedImage}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <Form.Group>
                                    <Form.Label
                                        id="label_input_first-name"
                                        className="label_input"
                                    >
                                        First Name:
                                    </Form.Label>
                                    <Form.Control
                                        id="input_firstName"
                                        name="firstName"
                                        value={newAccount.firstName}
                                        onChange={(e) => handleInputChange(e)}
                                        type="text"
                                        className="addNewAccount_input"
                                        required
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className="formControl_feedback"
                                    >
                                        Polje je obavezno
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Grid>
                            <Grid
                                item
                                md={12}
                                xs={12}
                            >
                                <Form.Group>
                                    <Form.Label
                                        id="label_input_email"
                                        className="label_input"
                                    >
                                        E-mail:
                                    </Form.Label>
                                    <Form.Control
                                        id="input_email"
                                        name="email"
                                        value={newAccount.email}
                                        onChange={(e) => handleInputChange(e)}
                                        type="email"
                                        className="addNewAccount_input"
                                        required
                                    />
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className="formControl_feedback"
                                    >
                                        Polje je obavezno
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Grid>
                            <Grid container>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Form.Group>
                                        <Form.Label id="label_input_phone" className="label_input">Country code:</Form.Label>
                                        <ReactSelect
                                            name="countryCode"
                                            id="addNewAccount_select_country-code"
                                            onChange={(e) => handleSelectChange(e)}
                                            className="reactSelect_street"
                                            options={countryCodeOptions}
                                        />
                                        <Form.Control.Feedback
                                            type="invalid"
                                            className="formControl_feedback"
                                        >
                                            Polje je obavezno
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <Form.Group>
                                        <Form.Label id="label_input_phone" className="label_input">Phone:</Form.Label>
                                        <Form.Control
                                            id="input_phone"
                                            name="phone"
                                            value={newAccount.phone}
                                            onChange={(e) => handleInputChange(e)}
                                            type="text"
                                            className="addNewAccount_input"
                                            required
                                        />
                                        <Form.Control.Feedback
                                            type="invalid"
                                            className="formControl_feedback"
                                        >
                                            Polje je obavezno
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <Form.Group>
                                <Form.Label
                                    id="label_input_last-name"
                                    className="label_input"
                                >
                                    Last Name:
                                </Form.Label>
                                <Form.Control
                                    id="input_lastName"
                                    name="lastName"
                                    value={newAccount.lastName}
                                    onChange={(e) => handleInputChange(e)}
                                    type="text"
                                    className="addNewAccount_input"
                                    required
                                />
                                <Form.Control.Feedback
                                    type="invalid"
                                    className="formControl_feedback"
                                >
                                    Polje je obavezno
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <Form.Group>
                                <Form.Label
                                    id="label_input_first-name"
                                    className="label_input"
                                >
                                    City:
                                </Form.Label>
                                <ReactSelect
                                    name="addNewAccountModal_select_city"
                                    className="reactSelect_street"
                                    placeholder={"Select city" || null}
                                    options={getCitiesOptions()}
                                    onChange={(e) => handleSelectChange(e)}

                                />
                            </Form.Group>
                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <Grid
                                item
                                md={12}
                            >
                                <Form.Group>
                                    <Form.Label
                                        id="label_select_street"
                                        className="label_input"
                                    >
                                        Street:
                                    </Form.Label>
                                    <ReactSelect
                                        id="select_street"
                                        placeholder="Select street"
                                        className="reactSelect_street"
                                        options={getStreetsOptions()}
                                        onChange={(e) => handleSelectChange(e)}
                                    />
                                </Form.Group>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            md={4}
                            xs={12}
                        >
                            <Form.Group>
                                <Form.Label
                                    id="label_input_last-name"
                                    className="label_input"
                                >
                                    Street numb.:
                                </Form.Label>
                                <Form.Control
                                    id="input_streetNumber"
                                    name="streetNumber"
                                    value={newAccount.streetNumber}
                                    onChange={(e) => handleInputChange(e)}
                                    type="text"
                                    className="addNewAccount_input"
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <Form.Group>
                                <Form.Label
                                    id="label_input_first-name"
                                    className="label_input"
                                >
                                    Role:
                                </Form.Label>
                                <ReactSelect
                                    name="role"
                                    id="select_role"
                                    onChange={(e) => handleSelectChange(e)}
                                    className="reactSelect_street"
                                    options={roles}
                                />
                                <Form.Control.Feedback
                                    type="invalid"
                                    className="formControl_feedback"
                                >
                                    Polje je obavezno
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}
                        >
                            <Form.Group>
                                <Form.Label
                                    id="label_input_note"
                                    className="label_input"
                                >
                                    Note:
                                </Form.Label>
                                <textarea
                                    value={newAccount.note}
                                    onChange={(e) => handleInputChange(e)}
                                    id="label_textArea_note"
                                    name="note"
                                    className="addNewAccount_input"
                                    rows={5}
                                    style={{ resize: "none" }}
                                />
                            </Form.Group>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid
                            item xs={6}
                            justify="flex-end"
                        >
                            <Tooltip
                                id="addNewAccount_tooltip-clear"
                                title="Clear fields"
                                arrow
                                placement="top-start"
                            >
                                <DeleteOutlineOutlinedIcon
                                    id="addNewAccount_icon-delete"
                                    className="addNewAccount_delete_button"
                                    onClick={clearSaveNewAccountField}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid
                            xs={6}
                            style={{ textAlign: "right" }}
                        >
                            <CreateOutlinedIcon
                                id="addNewAccount_icon-edit"
                                className="addNewAccount_edit_button"
                            />
                            <motion.button
                                whileHover={{ scale: 1.1, rotate: 360 }}
                                className="addNewAccount_icon_div"
                                type="submit"
                            >
                                <Tooltip
                                    id="addNewAccount_tooltip-save"
                                    title="Save account"
                                    placement="top-start"
                                >
                                    <SaveOutlinedIcon
                                        id="addNewAccount_icon-save"
                                        className="addNewAccount_save_button"
                                        onClick={handleSaveNewAccount}
                                    />
                                </Tooltip>
                            </motion.button>
                        </Grid>
                    </Grid>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddNewAccountModal;