import React from 'react';
import {
    Avatar,
    Grid,
    Typography,
    createStyles,
    makeStyles,
    Theme,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { selectAccount } from '../../slices/accountSlice';

interface Props{
    accountByEmail: any;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.up('md')]: {
                marginTop: "100px",
            },
            [theme.breakpoints.down('md')]: {
                marginTop: "50px",
            },
        },
        avatar: {
            backgroundColor: red[500],
            width: "200px",
            height: "200px",
            borderRadius: "50%"
        },
        content: {
            color: "#696969"
        },
        title: {
            color: "#B0B0B0"
        },
        socialIcons: {
            fontSize: "30px",
            textAlign: "center",
            marginLeft: "10px",
            position: "absolute",
            bottom: 0,
            [theme.breakpoints.down('md')]: {
                position: "sticky",
                justifyContent: "center",
                float: "right"
            },
        }
    }),
);

export default function InformationCard({
    accountByEmail
}: Props) {
    const classes = useStyles();
    const account = useSelector(selectAccount);

    return (
        <Grid
            id="information-card_container"
            container
            style={{ position: "relative" }}
        >
            <Grid
                item
                md={2}
                xs={12}
            >
                <img
                    id="information-card_avatar"
                    src={`data:image/jpeg;base64,${accountByEmail.userImageData200x200}`}
                    className={classes.avatar}
                />
            </Grid>
            <Grid
                item
                md={3}
                xs={12}
                style={{ padding: "10px" }}
            >
                <Typography
                    id="information-card_title_first-name"
                    variant="body1"
                    className={classes.title}
                >
                    First Name:
                </Typography>
                <Typography
                    id="information-card_content_first-name"
                    variant="h4"
                    className={classes.content}
                >
                    {account?.firstName}
                </Typography>
                <Grid
                    item
                    md={12}
                    xs={12}
                    style={{ marginTop: "5px" }}
                >
                    <Typography
                        id="information-card_title_e-mail"
                        variant="body1"
                        className={classes.title}
                    >
                        Email:
                    </Typography>
                    <Typography
                        id="information-card_content_e-mail"
                        variant="h4"
                        className={classes.content}
                    >
                        {account?.email}
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={12}
                    xs={12}
                    style={{ marginTop: "5px" }}
                >
                    <Typography
                        id="information-card_title_phone"
                        variant="body1"
                        className={classes.title}
                    >
                        Phone:
                    </Typography>
                    <Typography
                        id="information-card_content_phone"
                        variant="h4"
                        className={classes.content}
                    >
                        {account?.countryCode}/{account?.phone}
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                item
                md={3}
                xs={12}
                style={{ padding: "10px" }}
            >
                <Typography
                    id="information-card_title_last-name"
                    variant="body1"
                    className={classes.title}
                >
                    Last Name:
                </Typography>
                <Typography
                    id="information-card_content_last-name"
                    variant="h4"
                    className={classes.content}
                >
                    {account?.lastName}
                </Typography>
            </Grid>
            <Grid
                item
                md={3}
                xs={12}
                style={{ padding: "10px" }}
            >
                <Typography

                    variant="body1"
                    className={classes.title}
                >
                    Invoices:
                </Typography>
                <Typography
                    variant="h4"
                    className={classes.content}
                >
                    Recent
                </Typography>
                <Grid
                    item
                    xs={12}
                    md={12}
                    className={classes.socialIcons}
                >
                    <a href="https://www.facebook.com/midenasit" >
                        <motion.i
                            id="loginPage_icon-facebook"
                            className="fab fa-facebook social_icons_facebook"
                            whileHover={{ scale: 1.1, rotate: 360 }}
                        />
                    </a>
                    <a href="https://www.instagram.com/it_midenas/" >
                        <motion.i
                            id="loginPage_icon-instagram"
                            className="fab fa-instagram social_icons_instagram companyCard-instagram_icon_wrapper"
                            whileHover={{ scale: 1.1, rotate: 360 }}
                        />
                    </a>
                    <a href="https://www.linkedin.com/company/midenas-doo/about/">
                        <motion.i
                            id="loginPage_icon-linkedIn"
                            className="fab fa-linkedin social_icons_linkedIn"
                            whileHover={{ scale: 1.1, rotate: 360 }}
                        />
                    </a>
                </Grid>
            </Grid>
        </Grid>
    );
};