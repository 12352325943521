import SideBar from "./sidebar"

export default function LayoutSidebar(props: any) {
    const { children } = props
    return (
        <div style={{background: "#DBE3EA", height: "100vh"}}>
            <SideBar />
            {children}
        </div>
    );
};