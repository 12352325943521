import React, { useState } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme
} from '@material-ui/core/styles';
import { 
  Container, 
  Grid 
} from '@material-ui/core';

import './dashboardPage.css';
import LayoutSidebar from '../../sidebar/LayoutSidebar';

const drawerWidth = 250;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up('md')]: {
        marginTop: "100px",
      },
      [theme.breakpoints.down('md')]: {
        marginTop: "50px",
      },
    },
  }),
);

export default function DashboardPage() {
  const classes = useStyles();

  return (
    <LayoutSidebar>
      <Container maxWidth="lg" className={classes.root}>
        <Grid container>

        </Grid>
      </Container>
    </LayoutSidebar>
  );
};