import React, { useState } from 'react';
import {
    createStyles,
    makeStyles,
    Theme,
    useTheme
} from '@material-ui/core/styles';
import {
    Container,
    Grid,
    useMediaQuery
} from '@material-ui/core';
import LayoutSidebar from '../../sidebar/LayoutSidebar';
import AddNewAccountModal from '../../modals/addNewAccountModal/addNewAccountModal';
import AccountTable from '../../accountTable/accountTable';
import { useSelector } from 'react-redux';
import './accountPage.css';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.up('md')]: {
                marginTop: "100px",
            },
            [theme.breakpoints.down('md')]: {
                marginTop: "50px",
            },
            display: "flex",
            alignContent: "center",
            justifyContent: "center"
        },
    }),
);

export default function AccountPage() {
    const classes = useStyles();

    const [addNewEmployeeModalOpen, setAddNewEmployeeModalOpen] = useState(false);

    function hanldeAddNewEmployeeModalOpen(open: any) {
        setAddNewEmployeeModalOpen(open);
    };
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

    return (
        <LayoutSidebar>
            <Container maxWidth="lg" className={classes.root}>
                <Grid container>
                    {isDesktop && (
                        <Grid
                            item
                            xs={12}
                        >
                            <button
                                onClick={hanldeAddNewEmployeeModalOpen}
                                className="float-end accountPage_button"
                                type="submit"
                            >
                                + Add New Employee
                            </button>
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={12}
                        style={{ marginTop: "20px" }}
                    >
                        <AccountTable />
                    </Grid>
                    {isMobile && (
                        <Grid item xs={12}>
                            <button
                                onClick={hanldeAddNewEmployeeModalOpen}
                                style={{ marginTop: "5px" }}
                                className="accountPage_button"
                                type="submit"
                            >
                                + Add New Employee
                            </button>
                        </Grid>
                    )}
                </Grid>
            </Container>
            <AddNewAccountModal
                handleClose={() => hanldeAddNewEmployeeModalOpen(false)}
                isOpen={addNewEmployeeModalOpen}
            />
        </LayoutSidebar>
    );
};