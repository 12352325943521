import {
    createAsyncThunk,
    createSelector,
    createSlice
} from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import {
    getAllCities as getAllCitiesApi,
    getCityById as getCityByIdApi,
    getAllStreetsByCity as getAllStreetsByCityApi,
    getAllStreets as getAllStreetsApi
} from "../app/easyBusinessApi";
import { CityModel } from "../models/addressess/cityModel";
import { StreetModel } from "../models/addressess/streetModel";

interface AddressState {
    cities: CityModel[];
    streetsByCity: StreetModel[];
    streets: StreetModel[];
};

let init_city: CityModel = {
    description: "",
    id: "",
    name: ""
};

const city_state = [
    init_city
];

const init_street: StreetModel = {
    cityId: "",
    description: "",
    id: "",
    name: ""
}

const street_state = [
    init_street
];

let initialState: AddressState = {
    cities: city_state,
    streetsByCity: street_state,
    streets: []
}

export const selectCities = createSelector(
    (state: RootState) => state.address.cities,
    (cities: CityModel[]) => {
        return cities;
    }
);

export const selectStreetsByCity = createSelector(
    (state: RootState) => state.address.streetsByCity,
    (streetsByCity: StreetModel[]) => {
        return streetsByCity;
    }
);

export const getCities = createAsyncThunk("address/get_cities", async () => {
    const response = await getAllCitiesApi();
    return response;
});

export const getStreets = createAsyncThunk("address/get_streets", async () => {
    const response = await getAllStreetsApi();
    return response;
});

export const getAllStreetsByCity = createAsyncThunk("address/get_streets_by_city", async (id: string) => {
    const response = await getAllStreetsByCityApi(id);
    return response;
});

export const resetStreets = createAsyncThunk("address/reset_city", async () => {
    return null;
});


const addressSlice = createSlice({
    name: "address",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCities.fulfilled, (state, { payload }) => {
                if (payload.succeeded === true) {
                    var cityData = payload.data.result;
                    state.cities = cityData;
                }
            })
            .addCase(getCities.rejected, (state, { }) => {
                state.cities = [];
            })
            .addCase(getAllStreetsByCity.fulfilled, (state, { payload }) => {
                var streetData = payload.data.result;
                state.streetsByCity = streetData;
            })
            .addCase(getAllStreetsByCity.rejected, (state, { }) => {
                state.streetsByCity = [];
            })
            .addCase(getStreets.fulfilled, (state, { payload }) => {
                var allStreets = payload.data.result;
                state.streets = allStreets;
            })
            .addCase(resetStreets.fulfilled, (state, { }) => {
                state.streetsByCity = [];
            })
    },
})

export default addressSlice.reducer;