import { ApiResponse } from "../models/apiResponse";
import UserAccount from "../models/userAccount";
import { LoginResponse } from "../models/login/loginResponse";
import { ApiPagingResponseCity } from "../models/apiPagingResponseCity";
import { ApiPagingResponseStreet } from "../models/apiPagingResponseStreet";
import { ApiPagingResponseAccount } from "../models/apiPagingResponseAccount";
import api from "./api";
import ChangePasswordResponse from "../models/login/checkPasswordResponse";
import { ApiPagingResponseAccountByEmail } from "../models/apiPagingResponseAccountByEmail";

 const baseURL = "https://api.easybusiness.midenas.rs"
//const baseURL = "https://localhost:7161"

export const login = async (credentials: any) => {
    return api.post(baseURL + "/api/auth/login", credentials) as Promise<LoginResponse>;
};

export const resetPassword = async (request: any) => {
    return api.post(baseURL + "/api/accounts/reset-password", request) as Promise<LoginResponse>;
};

export const forgotPasswordRequest = async (request: any) => {
    return api.post(baseURL + "/api/accounts/reset-password-request", request) as Promise<LoginResponse>;
};

export const checkOldPassword = async (request: any) => {
    return api.post(baseURL + "/api/accounts/check-old-account-password", request) as Promise<ApiResponse>;
};

export const getAllAccounts = async () => {
    return api.get(baseURL + "/api/accounts") as Promise<ApiPagingResponseAccount>;
};

export const getAccountByEmail = async (email: string) => {
    return api.get(baseURL + "/api/accounts/get-account-by-email?email=" + email) as Promise<ApiPagingResponseAccountByEmail>;
};

export const getSelectedAccountByEmail = async (email: string) => {
    return api.get(baseURL + "/api/accounts/get-account-by-email?email=" + email) as Promise<ApiPagingResponseAccountByEmail>;
};

export const createAccount = async (newAccount: UserAccount) => {
    return api.post(baseURL + "/api/accounts", newAccount) as Promise<ApiResponse>;
};

export const getAllCities = async () => {
    return api.get(baseURL + "/api/addresses/city/get-all-cities") as Promise<ApiPagingResponseCity>;
};

export const getAllStreets = async () => {
    return api.get(baseURL + "/api/addresses/street/get-all-streets") as Promise<ApiPagingResponseStreet>;
};

export const getCityById = async () => {
    return api.get(baseURL + "/api/addresses/city/get-city-by-id") as Promise<ApiResponse>;
};

export const getAllStreetsByCity = async (id: string) => {
    return api.get(baseURL + "/api/addresses/street/get-all-streets-by-city-id?cityId=" + id) as Promise<ApiPagingResponseStreet>;
};