import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  Grid,
  Typography
} from "@material-ui/core";
import {
  Button,
  Modal,
  ModalHeader
} from "react-bootstrap";
import {
  forgotPasswordRequest,
  resetForgottenPassword,
  selectForgottenPassword
} from "../../../slices/accountSlice";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../app/hooks";
import { makeStyles } from '@material-ui/core/styles';
import Logo from "../../../images/easybusinessLogo.png";
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import ResetPasswordRequest from "../../../models/login/resetPasswordRequest";
import SnackbarModel from "../../../models/snackbarModel";
import { setSnackbar } from "../../../slices/snackbarSlice";
import './forgotPasswordModal.css';

interface Props {
  isOpen: boolean;
  handleClose: any
}

const useStyles = makeStyles({
  title: {
    fontWeight: 700,
    fontSize: "30px",
    lineHeight: "46px",
    color: "#8F8F8F"
  },
  modalHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: "none"
  }
});

const ForgotPasswordModal = ({
  handleClose,
  isOpen
}: Props) => {

  const dispatch = useAppDispatch();
  const classes = useStyles();

  const resetPassword = useSelector(selectForgottenPassword);

  const INIT_RESET_PASSWORD_REQUEST: ResetPasswordRequest = {
    email: "",
  };

  const [forgotPassword, setForgotPassword] = useState<ResetPasswordRequest>(INIT_RESET_PASSWORD_REQUEST);

  const handleInputChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    const newPasswordRequest = { ...forgotPassword };
    if (name === "email") {
      newPasswordRequest.email = value;
    }
    setForgotPassword(newPasswordRequest);
  };

  const handleResetPasswordRequest = () => {
    dispatch(forgotPasswordRequest(forgotPassword));
  };

  const clearResetPasswordRequestFields = () => {
    const updateRequest: ResetPasswordRequest = {
      email: ""
    };
    setForgotPassword(updateRequest);
  };

  useEffect(() => {
    if (resetPassword) {
      const snackbar: SnackbarModel = {
        message: "You have successfully send a email",
        severity: "success",
        show: true,
      }
      dispatch(setSnackbar(snackbar));
      clearResetPasswordRequestFields();
    } else {
      if (resetPassword !== null) {
        const snackbar: SnackbarModel = {
          message: "Email send failed, please check the entered data and try again.",
          severity: "error",
          show: true,
        }
        dispatch(setSnackbar(snackbar));
      }
    }
    dispatch(resetForgottenPassword());
  }, [resetPassword]);

  return (
    <Modal
      id="forgotPassword_modal"
      show={isOpen}
      onHide={handleClose}
      className="text-center"
      centered
    >
      <ModalHeader
        id="forgotPassword_modal-header"
        className={classes.modalHeader}
      >
        <img
          src={Logo}
          alt=""
          className="forgotPassword_logo"
        />
      </ModalHeader>
      <Modal.Body id="forgotPassword_modal-body">
        <Grid item md={12}>
          <Typography
            id="forgotPassword_modal-title"
            className={classes.title}
          >
            Forgot your password?
          </Typography>
        </Grid>
        <Grid item md={12}>
          <div className="form-group mt-3">
            <div className="forgotPassword_form_input">
              <span className="forgotPassword_icon_input">
                <PersonOutlineOutlinedIcon />
              </span>
              <input
                id="forgotPassword_input-email"
                type="email"
                name="email"
                placeholder="e-mail"
                className="forgotPassword_input"
                value={forgotPassword.email}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>
        </Grid>
          <motion.div className="d-grid gap-2 mt-3 p-1 forgotPassword_buttons_wrapper">
            <Button
              id="forgotPassword_button-send"
              type="submit"
              className="forgotPassword_send_button"
              onClick={handleResetPasswordRequest}
              size="sm"
            >
              Send me a password  reset link
            </Button>
          </motion.div>
          <motion.div className="d-grid gap-2 mt-2 p-1 forgotPassword_buttons_wrapper">
            <Button
              id="forgotPassword_button-cancel"
              type="submit"
              className="forgotPassword_cancel_button"
              onClick={handleClose}
              size="sm"
            >
              Cancel
            </Button>
          </motion.div>
      </Modal.Body>
    </Modal>
  );
};

export default ForgotPasswordModal;