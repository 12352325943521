import React, { useEffect, useState } from 'react';
import {
    Button,
    IconButton,
} from '@material-ui/core';
import {
    dashboardPage as dashboardRoute
} from '../../../app/routes';
import {
    Visibility,
    VisibilityOff
} from '@material-ui/icons';
import {
    getAccountByEmail,
    getAccounts,
    login,
    resetLoginFailed,
    selectAccount,
    selectIsFirstLogin,
    selectLoginFailed
} from '../../../slices/accountSlice';
import {
    resetSnackbar,
    setSnackbar
} from '../../../slices/snackbarSlice';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { useSelector } from 'react-redux';
import { getCities } from '../../../slices/addressSlice';
import { motion } from 'framer-motion';
import LoginRequest from '../../../models/login/loginRequest';
import Logo from '../../../images/easybusinessLogo.png';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ForgotPasswordModal from '../../modals/forgotPasswordModal/forgotPasswordModal';
import SnackbarModel from '../../../models/snackbarModel';
import SetPasswordModal from '../../modals/setPasswordModal/setPasswordModal';
import './loginPage.css';
import ResetPasswordModal from '../../modals/resetPasswordModal/resetPasswordModal';

export default function LoginPage() {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const loginFailed = useSelector(selectLoginFailed);
    const isFirstLogin = useSelector(selectIsFirstLogin);
    const account = useSelector(selectAccount);

    const INIT_LOGIN_REQUEST: LoginRequest = {
        email: "",
        password: ""
    };

    const [openSetPasswordModal, setOpenSetPasswordModal] = useState(false);
    const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isOpen, toggle] = useState(false);

    const [loginRequest, setLoginRequest] = useState<LoginRequest>(INIT_LOGIN_REQUEST);

    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    function handleOpenModal(open: any) {
        toggle(open);
    };

    function handleSetPasswordModalOpen(open: any) {
        setOpenSetPasswordModal(open);
    };

    function handleResetPasswordModalOpen(open: any) {
        setOpenResetPasswordModal(open);
    };

    const togglePassword = () => {
        setShowPassword((showPassword) => !showPassword);
    };

    const handleDashboard = () => {
        dispatch(login(loginRequest));
        dispatch(getAccounts());
        dispatch(getCities());
        dispatch(resetSnackbar());
        dispatch(getAccountByEmail(loginRequest?.email));
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
    };

    const handleOnChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        var newLoginRequest = { ...loginRequest }
        if (name === "email") {
            newLoginRequest.email = value;
        } else {
            newLoginRequest.password = value;
        }
        setLoginRequest(newLoginRequest);
    };

    useEffect(() => {
        if (loginFailed !== null && isFirstLogin != true) {
            const snackbar: SnackbarModel = {
                message: "Login failed, please check the entered data and try again.",
                severity: "error",
                show: true,
            }
            dispatch(setSnackbar(snackbar));
        }
        dispatch(resetLoginFailed());
    }, [loginFailed]);

    useEffect(() => {
        if (account!.email !== "" && isFirstLogin !== true) {
            history.push(dashboardRoute());
        };
    }, [account]);

    useEffect(() => {
        if (isFirstLogin === true) {
            setOpenSetPasswordModal(true);
        };
    }, [isFirstLogin]);

    return (
        <div className="form-container">
            <form
                id="loginPage_form"
                className="form"
                onSubmit={handleSubmit}
            >
                <div className="form-content">
                    <div className="logo_wrapper">
                        <img
                            id="loginPage_logo"
                            src={Logo}
                            alt=""
                            className="login_logo"
                        />
                    </div>
                    <div className="title_wrapper">
                        <h3
                            id="loginPage_title"
                            className="form-title"
                            onClick={handleResetPasswordModalOpen}
                        >
                            Login
                        </h3>
                    </div>
                    <div className="form-group mt-3">
                        <div className="form-input">
                            <span className="icon_input">
                                <PersonOutlineOutlinedIcon />
                            </span>
                            <input
                                id="loginPage_input-email"
                                type="email"
                                name="email"
                                placeholder="e-mail"
                                className="input"
                                onChange={(e) => handleOnChange(e)}
                            />
                        </div>
                    </div>
                    <div className="form-group mt-3">
                        <div className="form-input">
                            <span className="icon_input">
                                <LockOutlinedIcon />
                            </span>
                            <input
                                id="loginPage_input-password"
                                type={showPassword ? "text" : "password"}
                                name="password"
                                placeholder="password"
                                className="input"
                                onChange={(e) => handleOnChange(e)}
                            />
                            <span className="icon_visibility_wrapper">
                                <IconButton
                                    className="icon_visibility"
                                    aria-label="toggle password visibility"
                                    onClick={togglePassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ?
                                        <Visibility />
                                        :
                                        <VisibilityOff />}
                                </IconButton>
                            </span>
                        </div>
                        <div className="forgot_label_wrapper text-right">
                            <label
                                id="loginPage_forgotPasword"
                                className="forgot_label"
                                onClick={() => handleOpenModal(true)}
                            >
                                Forgot password?
                            </label>
                        </div>
                    </div>
                    <motion.div className="d-grid gap-2 mt-3">
                        <Button
                            id="loginPage_button-login"
                            type="submit"
                            className="login_button"
                            onClick={handleDashboard}
                            size='small'
                        >
                            Login
                        </Button>
                    </motion.div>
                </div>
                <div className="followUs_wrapper">
                    <hr className="hr" />
                    <h3 className="social_icons_title">Follow Us</h3>
                    <div className="social_icons" >
                        <a href="https://www.facebook.com/midenasit">
                            <motion.i
                                id="loginPage_icon-facebook"
                                className="fab fa-facebook social_icons_facebook"
                                whileHover={{ scale: 1.2, rotate: 360 }}
                            />
                        </a>
                        <a href="https://www.instagram.com/it_midenas/" >
                            <motion.i
                                id="loginPage_icon-instagram"
                                className="fab fa-instagram social_icons_instagram"
                                whileHover={{ scale: 1.2, rotate: 360 }}
                            />
                        </a>
                        <a href="https://www.linkedin.com/company/midenas-doo/about/">
                            <motion.i
                                id="loginPage_icon-linkedIn"
                                className="fab fa-linkedin social_icons_linkedIn"
                                whileHover={{ scale: 1.2, rotate: 360 }}
                            />
                        </a>
                    </div>
                    <ForgotPasswordModal
                        handleClose={() => handleOpenModal(false)}
                        isOpen={isOpen}
                    />
                    <SetPasswordModal
                        account={account}
                        handleClose={() => handleSetPasswordModalOpen(false)}
                        isOpen={openSetPasswordModal}
                    />
                    <ResetPasswordModal
                        account={account}
                        handleClose={() => handleResetPasswordModalOpen(false)}
                        isOpen={openResetPasswordModal}
                    />
                </div>
            </form>
        </div>
    );
};