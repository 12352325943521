import React, { useEffect, useState } from 'react';
import './App.css';
import Layout from './app/Layout';
import Routing from './app/Routing';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFontAwesome } from '@fortawesome/free-brands-svg-icons'
import { useSelector } from 'react-redux';
import { selectSnackbar } from './slices/snackbarSlice';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

library.add(fas, faTwitter, faFontAwesome)

function App() {
  const snackbar = useSelector(selectSnackbar);

  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    if (snackbar.show) {
      setOpenSnackbar(true);
    }
  }, [snackbar])

  const handleCloseSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };
  return (
    <Layout>
      <Routing />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Layout>
  );
}

export default App;
