import React, { useEffect, useState } from 'react';
import {
    Theme,
    createStyles,
    makeStyles
} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    Divider,
    Grid,
    Zoom
} from '@material-ui/core';
import { Form, InputGroup } from 'react-bootstrap';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
    Visibility,
    VisibilityOff
} from '@material-ui/icons';
import './accordion.css';

interface Props {
    password: any;
    changePasswordRequest: any;
    isChecked: any;
    onBlurOldPassword: any;
    checkOldPass: any;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            color: "#696969"
        },
        typographyText: {
            padding: "5px",
            fontSize: "18px",
            fontWeight: 700,
            color: "#9C9C9C",
            border: "none",
            background: "transparent",
            [theme.breakpoints.down("xs")]: {
                minWidth: "100%"
            },
            [theme.breakpoints.down("md")]: {
                minWidth: "100%"
            }
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    }),
);

export default function CustomAccordion({
    password,
    changePasswordRequest,
    isChecked,
    onBlurOldPassword,
    checkOldPass
}: Props) {
    const classes = useStyles();

    const [showPasswordOld, setShowPasswordOld] = useState(false);
    const [showPasswordNew, setShowPasswordNew] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

    const [showIcon, setShowIcon] = useState(false);

    const [validLength, setValidLength] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [upperCase, setUpperCase] = useState(false);
    const [specialChar, setSpecialChar] = useState(false);
    const [match, setMatch] = useState(false);
    const [requiredLength, setRequiredLength] = useState(8);
    const [validLenghtIcon, setValidLenghtIcon] = useState(false);
    const [validHasNumberIcon, setValidHasNumbertIcon] = useState(false);
    const [validUpperCaseIcon, setValidUpperCaseIcon] = useState(false);
    const [validSpecialCharIcon, setValidSpecialCharIcon] = useState(false);
    const [validMatchIcon, setValidMatchIcon] = useState(false);

    const togglePasswordOld = () => {
        setShowPasswordOld((showPasswordOld) => !showPasswordOld);
    };

    const togglePasswordNew = () => {
        setShowPasswordNew((showPasswordNew) => !showPasswordNew);
    };

    const togglePasswordConfirm = () => {
        setShowPasswordConfirm((showPasswordConfirm) => !showPasswordConfirm);
    };

    useEffect(() => {
        setValidLength(password.passwordNew.length >= requiredLength ? true : false);
        setValidLenghtIcon(password.passwordNew.length >= requiredLength ? true : false);
        setUpperCase(password.passwordNew.toLowerCase() !== password.passwordNew);
        setValidUpperCaseIcon(password.passwordNew.toLowerCase() !== password.passwordNew);
        setHasNumber(/\d/.test(password.passwordNew));
        setValidHasNumbertIcon(/\d/.test(password.passwordNew));
        setMatch(!!password.passwordNew && password.passwordNew === password.passwordConfirm);
        setValidMatchIcon(!!password.passwordNew && password.passwordNew === password.passwordConfirm);
        setSpecialChar(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password.passwordNew));
        setValidSpecialCharIcon(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password.passwordNew));
        if (password.passwordNew === "") {
            setShowIcon(false);
        }
    }, [password, requiredLength]);

    return (
        <div className={classes.root}>
            <Accordion id="change-password_accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        id="change-password_accordion_header"
                    >
                        <Typography
                            id="change-password_title"
                            className={classes.heading}
                            variant="h5"
                        >
                            Change Password
                        </Typography>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={6}
                            >
                                <InputGroup
                                    id="change-password_input-group"
                                    className="mb-3"
                                >
                                    <Typography
                                        id="change-password_text_old-password"
                                        variant="body1"
                                        className={classes.typographyText}
                                    >
                                        Old Password:
                                    </Typography>
                                    <Form.Control
                                        id="change-password_input_old-password"
                                        name="passwordOld"
                                        type={showPasswordOld ? "text" : "password"}
                                        onChange={(e) => onBlurOldPassword(e)}
                                        style={{ borderRight: "none" }}
                                        className="accordion_input outline-none"
                                        value={changePasswordRequest.oldPassword}
                                    />
                                    <InputGroup.Text
                                        id="basic-addon1"
                                        className="accordion_visibility-icon_wrapper"
                                        onClick={togglePasswordOld}
                                    >
                                        {showPasswordOld ?
                                            <Visibility
                                                id="change-password_icon_visibility-old"
                                                className="accordion_visibility-icon"
                                            />
                                            :
                                            <VisibilityOff
                                                id="change-password_icon_visibilityOff-old"
                                                className="accordion_visibility-icon"
                                            />
                                        }
                                    </InputGroup.Text>
                                    <InputGroup.Text className="accordion_check-icon_wrapper">
                                        {isChecked ?
                                            <span className="accordion_passwordValidationSuccess_icon">
                                                <CheckCircleIcon id="change-password_icon_check" />
                                            </span>
                                            :
                                            <span className="accordion_passwordValidationError_icon">
                                                <CancelIcon id="change-password_icon_cancel" />
                                            </span>
                                        }
                                    </InputGroup.Text>
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <Typography
                                        id="change-password_text_new-password"
                                        variant="body1"
                                        className={classes.typographyText}
                                    >
                                        Set New Password:
                                    </Typography>
                                    <Form.Control
                                        id="change-password_input_new-password"
                                        name="passwordNew"
                                        type={showPasswordNew ? "text" : "password"}
                                        onChange={(e) => onBlurOldPassword(e)}
                                        style={{ borderRight: "none" }}
                                        className="accordion_input"
                                        value={password.passwordNew}
                                    />
                                    <InputGroup.Text
                                        id="change-password_wrapper_visibility"
                                        className="accordion_visibility-icon_wrapper"
                                        onClick={togglePasswordNew}
                                    >
                                        {showPasswordNew ?
                                            <Visibility
                                                id="change-password_icon_visibility-set"
                                                className="accordion_visibility-icon"
                                            />
                                            :
                                            <VisibilityOff
                                                id="change-password_icon_visibilityOff-set"
                                                className="accordion_visibility-icon"
                                            />
                                        }
                                    </InputGroup.Text>
                                    <InputGroup.Text className="accordion_check-icon_wrapper">
                                        {password.passwordOld && (
                                            validLength
                                                &&
                                                hasNumber
                                                &&
                                                upperCase
                                                &&
                                                specialChar
                                                ?
                                                <Zoom in={
                                                    validLength
                                                    &&
                                                    hasNumber
                                                    &&
                                                    upperCase
                                                    &&
                                                    specialChar
                                                }
                                                >
                                                    <span className="accordion_passwordValidationSuccess_icon">
                                                        <CheckCircleIcon id="change-password_icon_check" />
                                                    </span>
                                                </Zoom>

                                                :
                                                <span className="accordion_passwordValidationError_icon">
                                                    <CancelIcon id="change-password_icon_cancel" />
                                                </span>
                                        )}
                                    </InputGroup.Text>
                                </InputGroup>
                                <InputGroup className="mb-3 accordion_input">
                                    <Typography
                                        id="change-password_text_confirm-password"
                                        variant="body1"
                                        className={classes.typographyText}
                                    >
                                        Confirm New Password:
                                    </Typography>
                                    <Form.Control
                                        id="change-password_input_confirm-password"
                                        name="passwordConfirm"
                                        type={showPasswordConfirm ? "text" : "password"}
                                        onChange={(e) => onBlurOldPassword(e)}
                                        style={{ borderRight: "none" }}
                                        className="accordion_input"
                                        value={changePasswordRequest.password}
                                    />
                                    <InputGroup.Text
                                        id="basic-addon1"
                                        className="accordion_visibility-icon_wrapper"
                                        onClick={togglePasswordConfirm}
                                    >
                                        {showPasswordConfirm ?
                                            <Visibility
                                                id="change-password_icon_visibility_confirm-password"
                                                className="accordion_visibility-icon"
                                            />
                                            :
                                            <VisibilityOff
                                                id="change-password_icon_visibilityOff_confirm-password"
                                                className="accordion_visibility-icon"
                                            />
                                        }
                                    </InputGroup.Text>
                                    <InputGroup.Text className="accordion_check-icon_wrapper">
                                        {password.passwordOld && (
                                            match ?
                                                <Zoom in={match}>
                                                    <span className="accordion_passwordValidationSuccess_icon">
                                                        <CheckCircleIcon id="change-password_icon_check_confirm-password" />
                                                    </span>
                                                </Zoom>
                                                :
                                                <span className="accordion_passwordValidationError_icon">
                                                    <CancelIcon id="change-password_icon_cancel_confirm-password" />
                                                </span>
                                        )}
                                    </InputGroup.Text>
                                </InputGroup>
                            </Grid>
                            <Grid
                                container
                                xs={12}
                                sm={5}
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {password.passwordNew && (
                                    <Grid style={{ marginBottom: "20px" }}>
                                        <Grid
                                            item
                                            md={12}
                                            className="accordion_passwordValidation_wrapper"
                                        >
                                            {validLenghtIcon ?
                                                <CheckCircleIcon
                                                    id="change-password_icon-passwordValidationSuccess"
                                                    fontSize="small"
                                                    className="accordion_passwordValidationSuccess_icon"
                                                />
                                                :
                                                <RadioButtonUncheckedIcon
                                                    id="change-password_icon-passwordValidationError"
                                                    fontSize="small"
                                                    className="accordion_passwordValidationError_icon"
                                                />
                                            }
                                            {validLength ?
                                                <Typography
                                                    id="change-password_validationSuccess-minimumCharacters"
                                                    className="accordion_passwordValidationSuccess"
                                                >
                                                    minimum 8 characters
                                                </Typography>
                                                :
                                                <Typography
                                                    id="change-password_validationError-minimumCharacters"
                                                    className="passwordValidationError"
                                                >
                                                    minimum 8 characters
                                                </Typography>
                                            }
                                        </Grid>
                                        <Grid
                                            item
                                            md={12}
                                            className="accordion_passwordValidation_wrapper"
                                        >
                                            {validUpperCaseIcon ?
                                                <CheckCircleIcon
                                                    id="change-password_icon-passwordValidationSuccess"
                                                    fontSize="small"
                                                    className="accordion_passwordValidationSuccess_icon"
                                                />
                                                :
                                                <RadioButtonUncheckedIcon
                                                    id="change-password_icon-passwordValidationError"
                                                    fontSize="small"
                                                    className="accordion_passwordValidationError_icon"
                                                />
                                            }
                                            {upperCase ?
                                                <Typography
                                                    id="change-password_validationSuccess-capitalLetter"
                                                    className="accordion_passwordValidationSuccess"
                                                >minimum 1 a capital (uppercase) letter
                                                </Typography>
                                                :
                                                <Typography
                                                    id="change-password_validationError-capitalLetter"
                                                    className="passwordValidationError"
                                                >
                                                    minimum 1 a capital (uppercase) letter
                                                </Typography>
                                            }
                                        </Grid>

                                        <Grid
                                            item
                                            md={12}
                                            className="accordion_passwordValidation_wrapper"
                                        >
                                            {validHasNumberIcon ?
                                                <CheckCircleIcon
                                                    id="setPassword_icon-passwordValidationSuccess"
                                                    fontSize="small"
                                                    className="accordion_passwordValidationSuccess_icon"
                                                />
                                                :
                                                <RadioButtonUncheckedIcon
                                                    id="setPassword_icon-passwordValidationError"
                                                    fontSize="small"
                                                    className="accordion_passwordValidationError_icon"
                                                />
                                            }
                                            {hasNumber ?
                                                <Typography
                                                    id="setPassword_validationSuccess-minimumNumber"
                                                    className="accordion_passwordValidationSuccess"
                                                >
                                                    minimum 1 number
                                                </Typography>
                                                :
                                                <Typography
                                                    id="setPassword_validationError-minimumNumber"
                                                    className="passwordValidationError"
                                                >
                                                    minimum 1 number
                                                </Typography>
                                            }
                                        </Grid>
                                        <Grid item md={12} className="accordion_passwordValidation_wrapper">
                                            {validSpecialCharIcon ?
                                                <CheckCircleIcon
                                                    id="setPassword_icon-passwordValidationSuccess"
                                                    fontSize="small"
                                                    className="accordion_passwordValidationSuccess_icon"
                                                />
                                                :
                                                <RadioButtonUncheckedIcon
                                                    id="setPassword_icon-passwordValidationError"
                                                    fontSize="small"
                                                    className="accordion_passwordValidationError_icon"
                                                />
                                            }
                                            {specialChar ?
                                                <Typography
                                                    id="setPassword_validationSuccess-specialCharacter"
                                                    className="accordion_passwordValidationSuccess"
                                                >
                                                    minimum 1 special character
                                                </Typography>
                                                :
                                                <Typography
                                                    id="setPassword_validationError-specialCharacter"
                                                    className="passwordValidationError"
                                                >
                                                    minimum 1 special character
                                                </Typography>
                                            }
                                        </Grid>

                                        <Grid
                                            item
                                            md={12}
                                            className="accordion_passwordValidation_wrapper"
                                        >
                                            {validMatchIcon ?
                                                <CheckCircleIcon
                                                    id="setPassword_icon-passwordValidationSuccess"
                                                    fontSize="small"
                                                    className="accordion_passwordValidationSuccess_icon"
                                                />
                                                :
                                                <RadioButtonUncheckedIcon
                                                    id="setPassword_icon-passwordValidationError"
                                                    fontSize="small"
                                                    className="accordion_passwordValidationError_icon"
                                                />
                                            }
                                            {match ?
                                                <Typography
                                                    id="setPassword_validationSuccess-passwordConfirm"
                                                    className="accordion_passwordValidationSuccess"
                                                >
                                                    password confirmed
                                                </Typography>
                                                :
                                                <Typography
                                                    id="setPassword_validationError-passwordConfirm"
                                                    className="passwordValidationError"
                                                >
                                                    password confirmed
                                                </Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </AccordionDetails>
            </Accordion>
        </div>
    );
};