import React from "react";
import { CssBaseline } from "@material-ui/core";
import { Row, Container } from "react-bootstrap";

interface Props {
    children?: React.ReactNode;
}

export default function Layout({ children }: Props) {
    return (
        <React.Fragment>
            <CssBaseline />
                <div style={{overflowX: "hidden"}}>
                    <Row id="row-layout">{children}</Row>
                </div>
        </React.Fragment>
    );
};