import React from 'react';
import {
    Avatar,
    Grid,
    Typography,
    createStyles,
    makeStyles,
    Theme,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectAccount } from '../../slices/accountSlice';
import { motion } from 'framer-motion';
import './companyInformationCard.css'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "relative"
        },
        avatar: {
            backgroundColor: "blue",
            width: "200px",
            height: "200px"
        },
        content: {
            color: "#696969"
        },
        title: {
            color: "#B0B0B0"
        },
        socialIcons: {
            fontSize: "30px",
            textAlign: "center",
            marginLeft: "10px",
            position: "absolute",
            bottom: 0,
            [theme.breakpoints.down('md')]: {
                position: "sticky",
                justifyContent: "center",
                float: "right"
            },
        }
    }),
);

export default function CompanyInformationCard() {
    const classes = useStyles();
    const account = useSelector(selectAccount);

    return (
        <Grid
            id="company-card_container"
            container
            className={classes.root}
        >
            <Grid
                item
                md={2}
            >
                <Avatar
                    id="company-card_avatar"
                    aria-label="avatar"
                    className={classes.avatar}
                >
                    C
                </Avatar>
            </Grid>
            <Grid
                item
                md={6}
                style={{ padding: "10px" }}
            >
                <Typography
                    id="company-card_title_company-name"
                    variant="body1"
                    className={classes.title}
                >
                    Company Name:
                </Typography>
                <Typography
                    id="company-card_content_company-name"
                    variant="h4"
                    className={classes.content}
                >
                    {account?.firstName}
                </Typography>
                <Grid
                    item
                    md={12}
                    xs={12}
                    style={{ marginTop: "5px" }}
                >
                    <Typography
                        id="company-card_title_e-mail"
                        variant="body1"
                        className={classes.title}
                    >
                        e-mail:
                    </Typography>
                    <Typography
                        id="company-card_content_e-mail"
                        variant="h4"
                        className={classes.content}
                    >
                        {account?.email}
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={12}
                    xs={12}
                    style={{ marginTop: "5px" }}
                >
                    <Typography
                        id="company-card_title_phone"
                        variant="body1"
                        className={classes.title}
                    >
                        Phone:
                    </Typography>
                    <Typography
                        id="company-card_content_phone"
                        variant="h4"
                        className={classes.content}
                    >
                        {account?.countryCode}/{account?.phone}
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                item
                md={3}
                xs={12}
                style={{ padding: "10px" }}
            >
                <Typography
                    id="company-card_title_company-address"
                    variant="body1"
                    className={classes.title}
                >
                    Company Address:
                </Typography>
                <Typography
                    id="company-card_content_company-address"
                    variant="h4"
                    className={classes.content}
                >
                    Recent
                </Typography>
                <Grid
                    item
                    xs={12}
                    md={12}
                    className={classes.socialIcons}
                >
                    <a href="https://www.facebook.com/midenasit" >
                        <motion.i
                            id="company-card_icon-facebook"
                            className="fab fa-facebook social_icons_facebook"
                            whileHover={{ scale: 1.1, rotate: 360 }}
                        />
                    </a>
                    <a href="https://www.instagram.com/it_midenas/" >
                        <motion.i
                            id="company-card_icon-instagram"
                            className="fab fa-instagram social_icons_instagram companyCard-instagram_icon_wrapper"
                            whileHover={{ scale: 1.1, rotate: 360 }}
                        />
                    </a>
                    <a href="https://www.linkedin.com/company/midenas-doo/about/">
                        <motion.i
                            id="company-card_icon-linkedIn"
                            className="fab fa-linkedin social_icons_linkedIn"
                            whileHover={{ scale: 1.1, rotate: 360 }}
                        />
                    </a>
                </Grid>
            </Grid>
        </Grid>
    );
};