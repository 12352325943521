import React, { useState } from 'react';
import {
    Avatar,
    TableFooter,
    TablePagination,
    Typography
} from '@material-ui/core';
import { Table } from 'react-bootstrap';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import TableViewModal from '../modals/tableViewModal/tableViewModal';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import HttpsOutlinedIcon from '@material-ui/icons/HttpsOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import { useSelector } from 'react-redux';
import {
    getSelectedAccountByEmail,
    resetSelectedAccount,
    selectAllAccounts,
    selectIsFirstLogin,
    selectSelectedAccountByEmail
} from '../../slices/accountSlice';
import './accountTable.css';
import { useAppDispatch } from '../../app/hooks';

interface Data {
    firstName: string;
    lastName: string;
    email: string;
};

export default function AccountTable() {
    const dispatch = useAppDispatch();
    const rows = useSelector(selectAllAccounts);
    const selectedAccount = useSelector(selectSelectedAccountByEmail);
    const [tableViewModalOpen, setTableViewModalOpen] = useState(false);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('lastName');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    function createDataRows(firstName: string, lastName: string, email: string) {
        return { firstName, lastName, email };
    };

    function createDataRowsHeader(title: string) {
        return { title };
    };

    const headerRows = [
        createDataRowsHeader('FIRST NAME'),
        createDataRowsHeader('LAST NAME'),
        createDataRowsHeader('E-MAIL'),
        createDataRowsHeader('ACTIVE'),
        createDataRowsHeader('LOCKED'),
        createDataRowsHeader('ROLE'),
        createDataRowsHeader('ACTION'),
    ];

    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    type Order = 'asc' | 'desc';

    function getComparator<Key extends keyof any>(
        order: Order,
        orderBy: Key,
    ): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
        const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 0));
        setPage(1);
    };

    function handleTableViewModalOpen(e: any) {
        const selectedEmail = e.target.dataset.email;
        dispatch(getSelectedAccountByEmail(selectedEmail));
        setTableViewModalOpen(true);
    };

    const tableViewModalClose = () => {
        setTableViewModalOpen(false);
        dispatch(resetSelectedAccount());
    };

    return (
        <Table className="table" responsive="md">
            <thead>
                <tr>
                    {headerRows.map((headerRow, index) => (
                        <th
                            key={index}>
                            <Typography variant="h6">
                                {headerRow.title}
                            </Typography>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody className="accountTable_body">
                {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                        return (
                            <tr key={index}>
                                <td>{row.firstName}</td>
                                <td>{row.lastName}</td>
                                <td>{row.email}</td>
                                <td>
                                    {row.active !== true ?
                                        <FiberManualRecordIcon className="accountTable_inactive_icon" />
                                        :
                                        <FiberManualRecordIcon className="accountTable_active_icon" />
                                    }
                                </td>
                                <td>
                                    {row.locked !== true ?
                                        <LockOpenOutlinedIcon className="accountTable_lock_icon" />
                                        :
                                        <HttpsOutlinedIcon className="accountTable_lock_icon" />
                                    }
                                </td>
                                <td>{row.role}</td>
                                <td>
                                    <CreateOutlinedIcon
                                        key={index}
                                        data-email={row.email}
                                        className="accountTable_edit_icon"
                                    />
                                    <VisibilityOutlinedIcon
                                        key={index}
                                        data-email={row.email}
                                        className="accountTable_visibility_icon"
                                        onClick={(e) => handleTableViewModalOpen(e)}
                                        id={row.email}
                                    />
                                    <DeleteOutlineOutlinedIcon
                                        key={index}
                                        data-email={row.email}
                                        className="accountTable_delete_icon"
                                    />
                                </td>
                            </tr>
                        );
                    })}
            </tbody>
            <TableFooter className="accountTable_tableFooter">
                <TablePagination
                    align='right'
                    rowsPerPageOptions={[5, 10]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableFooter>
            <div>
                <TableViewModal
                    handleClose={tableViewModalClose}
                    isOpen={tableViewModalOpen}
                    selectedAccount={selectedAccount}
                />
            </div>
        </Table>
    );
};