/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import user from '../../../images/avatar.png';
import {
    alpha,
    makeStyles,
    createStyles
} from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@material-ui/icons/Search';
import {InputBase} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
    createStyles({
        search: {
            border: '1px solid gray',
            borderRadius: '40px',
            position: 'relative',
            backgroundColor: alpha(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: alpha(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
    }),
);

export default function AddClientPage() {
    const classes = useStyles();
    const { i18n } = useTranslation();

    return (
        <div className="dashboard_container">
            <div className="dashboard_navigation">
            </div>
            <div className="dashboard_main">
                <div className="dashboard_topbar">
                    <div className="dasboard_toggle">
                    </div>
                    <div className="dashboard_search">
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder="Search Here..."
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </div>
                    </div>
                    <div className="dashboard_user">
                        <img
                            src={user}
                            alt=""
                            className="dashboard_userLogo"
                        />
                    </div>
                </div>
                <div>
                    <h1>Add Client Page</h1>
                </div>
            </div>
        </div>
    );
};